import React from 'react';
import { Alert, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * Alert component that shows when a user hits a plan limitation
 * @param {Object} props
 * @param {string} props.message - The message explaining the limitation
 * @param {boolean} props.show - Whether to show the alert
 * @param {function} props.onClose - Function to call when the alert is closed
 */
const UpgradeAlert = ({ message, show = true, onClose }) => {
  const navigate = useNavigate();
  
  if (!show) return null;
  
  const handleUpgrade = () => {
    navigate('/suscripcion');
  };
  
  return (
    <Box sx={{ my: 2 }}>
      <Alert 
        severity="warning" 
        onClose={onClose}
        action={
          <Button 
            color="primary" 
            size="small" 
            variant="contained"
            onClick={handleUpgrade}
          >
            Suscríbete a 4Docentes PRO
          </Button>
        }
      >
        {message}
      </Alert>
    </Box>
  );
};

export default UpgradeAlert; 