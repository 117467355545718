import api from "./Api";

class ProgramacionDidacticaService {
    getProgramaciones() {
        return api.get("/ai/programaciones/");
    }

    createProgramacion(data) {
        return api.post("/ai/programaciones/", data);
    }

    getProgramacionById(id) {
        return api.get(`/ai/programaciones/${id}/`);
    }

    updateProgramacion(id, data) {
        // Ensure unidades_didacticas is an array if present
        const processedData = { ...data };
        if (processedData.unidades_didacticas && !Array.isArray(processedData.unidades_didacticas)) {
            processedData.unidades_didacticas = [];
        }
        
        // Make sure each unidad has the required structure
        if (Array.isArray(processedData.unidades_didacticas)) {
            processedData.unidades_didacticas = processedData.unidades_didacticas.map((unidad, index) => ({
                numero: unidad.numero || index + 1,
                titulo: unidad.titulo || '',
                temporalizacion: unidad.temporalizacion || '',
                justificacion: unidad.justificacion || '',
                tabla: unidad.tabla || ''
            }));
        }
        
        return api.put(`/ai/programaciones/${id}/`, processedData);
    }

    deleteProgramacion(id) {
        return api.delete(`/ai/programaciones/${id}/`);
    }

    updateProgramacionStep(id, step, data) {
        if (step === 'situaciones_aprendizaje') {
            return api.put(`/ai/programaciones/${id}/steps/${step}/`, {
                situaciones_aprendizaje: data.situaciones_aprendizaje,
                plan_anual_situaciones: data.plan_anual_situaciones
            });
        }
        if (step === 'unidades_didacticas') {
            return api.put(`/ai/programaciones/${id}/steps/${step}/`, {
                unidades_didacticas: data.unidades_didacticas || [],
                plan_anual_situaciones: data.plan_anual_situaciones || ''
            });
        }
        return api.put(`/ai/programaciones/${id}/steps/${step}/`, data);
    }

    updateInformacionPreliminar(id, data) {
        return this.updateProgramacionStep(id, 'informacion_preliminar', data);
    }

    updateTematicaHiloConductor(id, data) {
        return this.updateProgramacionStep(id, 'tematica_hilo_conductor', data);
    }

    updateIntroduccion(id, data) {
        return this.updateProgramacionStep(id, 'introduccion', data);
    }

    updateAtencionDiversidad(id, data) {
        return this.updateProgramacionStep(id, 'atencion_diversidad', data);
    }

    updateAccionTutorial(id, data) {
        return this.updateProgramacionStep(id, 'accion_tutorial', data);
    }

    updateElementosTransversales(id, data) {
        return this.updateProgramacionStep(id, 'elementos_transversales', data);
    }

    updateMetodologia(id, data) {
        return this.updateProgramacionStep(id, 'metodologia', data);
    }

    updateEvaluacion(id, data) {
        return this.updateProgramacionStep(id, 'evaluacion', data);
    }

    updateSituacionesAprendizaje(id, data) {
        return this.updateProgramacionStep(id, 'situaciones_aprendizaje', data);
    }

    updateConclusionesBibliografia(id, data) {
        return this.updateProgramacionStep(id, 'conclusiones_bibliografia', data);
    }

    generateMarkdown(id) {
        return api.post(`/ai/programaciones/${id}/generate-final/`, {});
    }

    downloadAsPdf(id) {
        return api.get(`/ai/programaciones/${id}/download/pdf/`, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `programacion_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }

    downloadAsDocx(id) {
        return api.get(`/ai/programaciones/${id}/download/docx/`, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `programacion_${id}.docx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }
}

export default new ProgramacionDidacticaService(); 