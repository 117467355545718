import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AIService from '../../../services/AIService';
import { getMaxSituacionesAllowed } from '../../../utils/PlanUtils';
import UpgradeAlert from '../../subscription/UpgradeAlert';
const SituacionesAprendizaje = ({ programacion, setProgramacion, userPlan, maxSituaciones, setIsGeneratingAI, updateLoadingState }) => {
    const [loading, setLoading] = useState(false);
    const [inicializando, setInicializando] = useState(false);
    const [generandoSituacion, setGenerandoSituacion] = useState(null);
    const [tablasCargando, setTablasCargando] = useState({});
    
    const limiteSituaciones = maxSituaciones || getMaxSituacionesAllowed(userPlan);

    useEffect(() => {
        const isAnyLoading = loading || inicializando || generandoSituacion !== null || Object.values(tablasCargando).some(v => v);
        if (updateLoadingState) {
            updateLoadingState(isAnyLoading);
        }
    }, [loading, inicializando, generandoSituacion, tablasCargando, updateLoadingState]);

    const handleChange = (index, field, value) => {
        const newSituaciones = [...programacion.unidades_didacticas];
        newSituaciones[index] = {
            ...newSituaciones[index],
            [field]: value
        };
        setProgramacion({
            ...programacion,
            unidades_didacticas: newSituaciones
        });
    };

    const extraerTitulosDePlanAnual = (planAnual) => {
        const lineas = planAnual.split('\n');
        const titulos = [];
        let headerRow = true;
        
        for (const linea of lineas) {
            if (linea.includes('|')) {
                // Saltamos la primera fila (encabezado) y la segunda (separador con ---)
                if (headerRow) {
                    headerRow = false;
                    continue;
                }
                
                if (linea.includes('---')) {
                    continue;
                }
                
                const partes = linea.split('|').map(p => p.trim());
                // Verificamos que la línea tenga suficientes columnas y no sea una fila de encabezado
                if (partes.length >= 4) {
                    titulos.push(partes[3]);
                }
            }
        }
        return titulos;
    };

    const obtenerTemporalizacionDePlanAnual = (planAnual) => {
        const lineas = planAnual.split('\n');
        const temporalizaciones = [];
        let headerRow = true;
        
        for (const linea of lineas) {
            if (linea.includes('|')) {
                // Saltamos la primera fila (encabezado) y la segunda (separador con ---)
                if (headerRow) {
                    headerRow = false;
                    continue;
                }
                
                if (linea.includes('---')) {
                    continue;
                }
                
                const partes = linea.split('|').map(p => p.trim());
                // Verificamos que la línea tenga suficientes columnas y no sea una fila de encabezado
                if (partes.length >= 3) {
                    temporalizaciones.push(partes[2]);
                }
            }
        }
        return temporalizaciones;
    };

    const generarPlanAnual = async () => {
        setInicializando(true);
        setIsGeneratingAI(true);
        try {
            const numeroSituaciones = Math.min(programacion.numero_situaciones_aprendizaje, limiteSituaciones);
            
            const response = await AIService.generateSituacionesAprendizaje({
                materia: programacion.asignaturas[0]?.name,
                nivel: programacion.nivel_educativo?.id,
                comunidad: programacion.comunidad_autonoma,
                tematica: programacion.introduccion?.tematica_hilo_conductor,
                numero_situaciones: numeroSituaciones,
                id: programacion.id,
                idioma: programacion.idioma || 'Español'
            });

            const planAnual = response.data.plan_anual;
            const titulos = extraerTitulosDePlanAnual(planAnual);
            const temporalizaciones = obtenerTemporalizacionDePlanAnual(planAnual);

            const situacionesVacias = Array(numeroSituaciones).fill(null)
                .map((_, index) => ({
                    numero: index + 1,
                    titulo: titulos[index] || '',
                    justificacion: '',
                    temporalizacion: temporalizaciones[index] || '',
                    tabla: ''
                }));

            setProgramacion({
                ...programacion,
                numero_situaciones_aprendizaje: numeroSituaciones,
                plan_anual_situaciones: planAnual,
                unidades_didacticas: situacionesVacias
            });

            setGenerandoSituacion(0);
        } catch (error) {
            console.error('Error generando plan anual:', error);
            setIsGeneratingAI(false);
        } finally {
            setInicializando(false);
        }
    };

    const generarJustificacion = async (index, continueToNext = true) => {
        if (!programacion.unidades_didacticas[index]) return;

        setLoading(true);
        setIsGeneratingAI(true);
        try {
            const response = await AIService.generateSituacionAprendizajeDescripcion({
                materia: programacion.asignaturas[0]?.name,
                nivel: programacion.nivel_educativo?.id,
                comunidad: programacion.comunidad_autonoma,
                tematica: programacion.introduccion?.tematica_hilo_conductor,
                titulo: programacion.unidades_didacticas[index].titulo,
                id: programacion.id,
                idioma: programacion.idioma || 'Español'
            });

            const justificacion = response.data.output || 
                `Justificación para la unidad didáctica "${programacion.unidades_didacticas[index].titulo || `Unidad Didáctica ${index + 1}`}". Esta justificación ha sido generada como respaldo.`;

            await new Promise(resolve => {
                const newSituaciones = [...programacion.unidades_didacticas];
                newSituaciones[index] = {
                    ...newSituaciones[index],
                    justificacion
                };

                setProgramacion(prevProgramacion => {
                    const updatedProgramacion = {
                        ...prevProgramacion,
                        unidades_didacticas: newSituaciones
                    };
                    resolve();
                    return updatedProgramacion;
                });
            });

            if (response.data.output) {
                await generarTabla(index);
            }

            if (continueToNext && index < programacion.numero_situaciones_aprendizaje - 1) {
                setGenerandoSituacion(index + 1);
            } else {
                setGenerandoSituacion(null);
                setIsGeneratingAI(false);
            }
        } catch (error) {            
            const fallbackJustificacion = `Justificación para la unidad didáctica "${programacion.unidades_didacticas[index].titulo || `Unidad Didáctica ${index + 1}`}". Esta justificación ha sido generada automáticamente porque hubo un error en la generación.`;
            
            const newSituaciones = [...programacion.unidades_didacticas];
            newSituaciones[index] = {
                ...newSituaciones[index],
                justificacion: fallbackJustificacion
            };
            
            setProgramacion({
                ...programacion,
                unidades_didacticas: newSituaciones
            });
            
            if (continueToNext && index < programacion.numero_situaciones_aprendizaje - 1) {
                setGenerandoSituacion(index + 1);
            } else {
                setGenerandoSituacion(null);
                setIsGeneratingAI(false);
            }
        } finally {
            setLoading(false);
        }
    };

    const generarTabla = async (index) => {
        if (!programacion.unidades_didacticas[index]) return;

        try {
            setTablasCargando(prev => ({ ...prev, [index]: true }));
            setIsGeneratingAI(true);
            const response = await AIService.generateSituacionAprendizajeTabla({
                materia: programacion.asignaturas[0]?.name,
                nivel: programacion.nivel_educativo?.id,
                comunidad: programacion.comunidad_autonoma,
                tematica: programacion.introduccion?.tematica_hilo_conductor,
                titulo: programacion.unidades_didacticas[index].titulo,
                description: programacion.unidades_didacticas[index].justificacion,
                subject_id: programacion.asignaturas[0]?.uuid,
                temporalizacion: programacion.unidades_didacticas[index].temporalizacion,
                id: programacion.id,
                idioma: programacion.idioma || 'Español'
            });

            let safeHtml = response.data.output || '';
            
            if (!safeHtml.includes('<table') && safeHtml.trim().length > 0) {
                safeHtml = `<table border="1" style="width:100%;border-collapse:collapse;"><tr><td>${safeHtml}</td></tr></table>`;
            }
            
            if (safeHtml.includes('<table') && !safeHtml.includes('</table>')) {
                safeHtml += '</table>';
            }

            setProgramacion(prevProgramacion => {
                const newSituaciones = [...prevProgramacion.unidades_didacticas];
                newSituaciones[index] = {
                    ...prevProgramacion.unidades_didacticas[index],
                    tabla: safeHtml
                };

                return {
                    ...prevProgramacion,
                    unidades_didacticas: newSituaciones
                };
            });
        } catch (error) {
            console.error('Error generando tabla:', error);
        } finally {
            setTablasCargando(prev => ({ ...prev, [index]: false }));
        }
    };

    const regenerarTabla = async (index) => {
        setIsGeneratingAI(true);
        try {
            await generarTabla(index);
        } finally {
            setIsGeneratingAI(false);
        }
    };

    useEffect(() => {
        // Si el número de situaciones excede el límite, ajustarlo
        if (programacion.numero_situaciones_aprendizaje > limiteSituaciones) {
            setProgramacion(prev => ({
                ...prev,
                numero_situaciones_aprendizaje: limiteSituaciones
            }));
        }
        
        if (!inicializando && (!programacion.unidades_didacticas || programacion.unidades_didacticas.length === 0)) {
            generarPlanAnual();
        }
    }, [programacion.unidades_didacticas, inicializando, limiteSituaciones]);

    useEffect(() => {
        if (programacion && !Array.isArray(programacion.unidades_didacticas)) {
            setProgramacion({
                ...programacion,
                unidades_didacticas: []
            });
        }
    }, [programacion]);

    useEffect(() => {
        if (generandoSituacion !== null && !loading) {
            generarJustificacion(generandoSituacion, true);
        }
    }, [generandoSituacion, loading]);

    useEffect(() => {
        return () => {
            if (setIsGeneratingAI) {
                setIsGeneratingAI(false);
            }
            if (updateLoadingState) {
                updateLoadingState(false);
            }
        };
    }, [setIsGeneratingAI, updateLoadingState]);

    const renderPlanAnualTable = () => {
        if (!programacion.plan_anual_situaciones) return null;

        const rows = programacion.plan_anual_situaciones
            .split('\n')
            .filter(row => row.trim() && row.includes('|'))
            .filter(row => !row.includes('----|'))
            .map(row => row.split('|').map(cell => cell.trim()));

        return (
            <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {rows[0].map((cell, index) => (
                                <TableCell key={index}>{cell}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(1).map((row, index) => (
                            <TableRow key={index}>
                                {row.map((cell, cellIndex) => (
                                    <TableCell key={cellIndex}>{cell}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderizarTabla = (htmlContent) => {
        try {
            if (!htmlContent || !htmlContent.includes('<table')) {
                return (
                    <Typography color="error">
                        No se pudo generar una tabla válida. Intente regenerar la tabla.
                    </Typography>
                );
            }
            return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
        } catch (error) {
            console.error('Error rendering HTML:', error);
            return (
                <Typography color="error">
                    Error al renderizar la tabla. Intente regenerar la tabla.
                </Typography>
            );
        }
    };

    return (
        <Box>
            {limiteSituaciones < programacion.numero_situaciones_aprendizaje && (
                <UpgradeAlert
                    message={`Tu plan actual limita el número de situaciones de aprendizaje en ${limiteSituaciones}.`}
                    show={true}
                    onClose={() => {}}
                />
            )}
            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="h6">
                            Unidades Didácticas
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={generarPlanAnual}
                            disabled={inicializando || generandoSituacion !== null}
                            startIcon={<AutorenewIcon />}
                        >
                            Regenerar todo el contenido
                        </Button>
                    </Box>
                </Grid>

                {(inicializando || generandoSituacion !== null) && (
                    <Grid item xs={12}>
                        <Box sx={{ width: '100%', mb: 2 }}>
                            <LinearProgress 
                                variant="determinate" 
                                value={generandoSituacion !== null ? 
                                    ((generandoSituacion + 1) / programacion.numero_situaciones_aprendizaje) * 100 : 
                                    0
                                } 
                            />
                            <Typography variant="body2" color="text.secondary" align="center" mt={1}>
                                {inicializando ? 
                                    "Generando plan anual..." : 
                                    `Generando unidad didáctica ${generandoSituacion + 1} de ${programacion.numero_situaciones_aprendizaje}`
                                }
                            </Typography>
                        </Box>
                    </Grid>
                )}

                <Grid item xs={12}>
                    {renderPlanAnualTable()}
                </Grid>

                <Grid item xs={12}>
                    {programacion.unidades_didacticas?.map((unidad, index) => (
                        <Accordion key={index} sx={{ mb: 2 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>
                                    Unidad Didáctica {index + 1}: {unidad.titulo}
                                    {generandoSituacion === index && (
                                        <CircularProgress size={20} sx={{ ml: 2 }} />
                                    )}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Título"
                                            value={unidad.titulo}
                                            onChange={(e) => handleChange(index, 'titulo', e.target.value)}
                                            disabled={generandoSituacion !== null}
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Temporalización"
                                            value={unidad.temporalizacion}
                                            onChange={(e) => handleChange(index, 'temporalizacion', e.target.value)}
                                            disabled={generandoSituacion !== null}
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Justificación/Descripción"
                                            value={unidad.justificacion}
                                            onChange={(e) => handleChange(index, 'justificacion', e.target.value)}
                                            disabled={generandoSituacion !== null}
                                            multiline
                                            rows={6}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            onClick={() => generarJustificacion(index, false)}
                                            disabled={generandoSituacion !== null}
                                            startIcon={<AutorenewIcon />}
                                        >
                                            Regenerar justificación
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper elevation={0} sx={{ mb: 2 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                                <Typography variant="subtitle1">Tabla de Contenidos</Typography>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => regenerarTabla(index)}
                                                    disabled={generandoSituacion !== null || !unidad.justificacion || tablasCargando[index]}
                                                    startIcon={tablasCargando[index] ? <CircularProgress size={20} /> : <AutorenewIcon />}
                                                >
                                                    {tablasCargando[index] ? 'Generando tabla...' : 'Regenerar tabla'}
                                                </Button>
                                            </Box>
                                            {unidad.tabla ? (
                                                <Box 
                                                    sx={{ 
                                                        position: 'relative',
                                                        '& table': {
                                                            width: '90%',
                                                            borderCollapse: 'collapse',
                                                            mb: 2
                                                        },
                                                        '& th, & td': {
                                                            border: '1px solid #ddd',
                                                            textAlign: 'left'
                                                        },
                                                        '& th': {
                                                            backgroundColor: '#f5f5f5'
                                                        }
                                                    }}
                                                >
                                                    {renderizarTabla(unidad.tabla)}
                                                    {tablasCargando[index] && (
                                                        <Box 
                                                            sx={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                zIndex: 1
                                                            }}
                                                        >
                                                            <CircularProgress size={40} sx={{ mb: 2 }} />
                                                            <Typography>
                                                                Generando nueva tabla...
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            ) : (
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3 }}>
                                                    {tablasCargando[index] ? (
                                                        <>
                                                            <CircularProgress size={40} sx={{ mb: 2 }} />
                                                            <Typography color="text.secondary">
                                                                Generando tabla de contenidos...
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography color="text.secondary">
                                                            La tabla de contenidos se generará automáticamente cuando se genere la justificación.
                                                        </Typography>
                                                    )}
                                                </Box>
                                            )}
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
};

export default SituacionesAprendizaje; 