import React, { useState, useEffect } from 'react';
import { 
  Box,
  Stepper,
  Step,
  StepButton,
  Button,
  Typography,
  Container,
  Paper,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Alert,
  LinearProgress,
  IconButton,
  Collapse
} from '@mui/material';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import ProgramacionDidacticaService from '../../services/ProgramacionDidacticaService';
import { LEVELS, COMUNIDADES } from './utils';
import SubjectService from '../../services/SubjectService';
import { ArrowBack, ExpandMore, ExpandLess } from '@mui/icons-material';
import SettingsService from '../../services/SettingsService';
import { getMaxSituacionesAllowed } from '../../utils/PlanUtils';
import UpgradeAlert from '../subscription/UpgradeAlert';

import InformacionBasica from './Steps/InformacionBasica';
import Introduccion from './Steps/Introduccion';
import MarcoLegislativo from './Steps/MarcoLegislativo';
import Contexto from './Steps/Contexto';
import ElementosTransversales from './Steps/ElementosTransversales';
import Metodologia from './Steps/Metodologia';
import Evaluacion from './Steps/Evaluacion';
import AtencionDiversidad from './Steps/AtencionDiversidad';
import SituacionesAprendizaje from './Steps/SituacionesAprendizaje';
import Conclusiones from './Steps/Conclusiones';
import Bibliografia from './Steps/Bibliografia';

const STEPS = [
  'Información Básica',
  'Introducción',
  'Marco Legislativo',
  'Contexto',
  'Elementos Transversales',
  'Metodología',
  'Evaluación',
  'Atención Diversidad',
  'Unidades Didácticas',
  'Conclusiones',
  'Bibliografía'
];

const initialProgramacion = {
  titulo: 'Mi nueva programación didáctica',
  estado: 'borrador',
  ano: 2025,
  comunidad_autonoma: COMUNIDADES[0],
  centro_educativo: 'Mi Centro',
  numero_situaciones_aprendizaje: 12,
  nivel_educativo: { id: '', name: '' },
  asignaturas: [],
  idioma: 'Español',
  
  introduccion: {
    introduccion: '',
    tematica_hilo_conductor: ''
  },
  
  marco_legislativo: {
    marco_legislativo: '',
    justificacion: ''
  },
  
  contexto: {
    contexto: ''
  },
  
  elementos_transversales: {
    educacion_valores: '',
    objetivos_desarrollo_sostenible: ''
  },
  
  metodologia: {
    principios_segun_normativa: '',
    principios_propios: '',
    organizacion_espacial: '',
    organizacion_temporal: '',
    recursos: '',
    agrupamientos: '',
    tipos_actividades: ''
  },

  evaluacion: {
    que_evaluar: '',
    como_evaluar: '',
    cuando_evaluar: ''
  },
  
  atencion_diversidad: {
    marco_legal: '',
    medidas_ordinarias: '',
    medidas_extraordinarias: '',
    acneae: ''
  },
  
  situaciones_aprendizaje: [],
  plan_anual_situaciones: '',
  
  conclusiones: {
    conclusiones: ''
  },
  
  bibliografia: {
    bibliografia: '',
    webgrafia: ''
  }
};

const ProgramacionWizard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(parseInt(searchParams.get('paso')) || 0);
  const [programacion, setProgramacion] = useState(initialProgramacion);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(id ? true : false);
  const [error, setError] = useState(null);
  const [finalizando, setFinalizando] = useState(false);
  const [progreso, setProgreso] = useState(0);
  const nivelesEducativos = LEVELS;
  const [asignaturas, setAsignaturas] = useState([]);
  const [userPlan, setUserPlan] = useState('');
  const [showPlanAlert, setShowPlanAlert] = useState(false);
  const [isGeneratingAI, setIsGeneratingAI] = useState(false);
  const [isAnyLoading, setIsAnyLoading] = useState(false);
  const [stepperExpanded, setStepperExpanded] = useState(false);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const isInfantilLevel = programacion?.nivel_educativo?.id >= 28 && programacion?.nivel_educativo?.id <= 30;

  useEffect(() => {
    SettingsService.getProfileInfo()
      .then(response => {
        setUserPlan(response.data.plan || '');
      })
      .catch(err => {
        console.error('Error al cargar información del perfil:', err);
      });
  }, []);

  useEffect(() => {
    if (id) {
      ProgramacionDidacticaService.getProgramacionById(id)
        .then(async response => {
          let subjects = [];
          if (response.data.nivel_educativo && response.data.comunidad_autonoma) {
            const region = COMUNIDADES.find(c => c.name === response.data.comunidad_autonoma);
            if (region) {
              try {
                const subjectsResponse = await SubjectService.getLomloeSubjectsByRegionAndLevel(
                  region.id, 
                  response.data.nivel_educativo
                );
                subjects = subjectsResponse.data;
              } catch (error) {
              }
            }
          }

          let asignaturas = [];
          const asignaturasData = response.data.informacion_basica?.asignaturas || response.data.asignaturas;

          if (Array.isArray(asignaturasData)) {
            asignaturas = asignaturasData.map(asig => {
              if (asig.uuid && asig.name && asig.autonomous_community) {
                return {
                  id: asig.uuid,
                  uuid: asig.uuid,
                  name: asig.name,
                  autonomous_community: asig.autonomous_community,
                  is_regulated: asig.is_regulated,
                  course_level: asig.course_level
                };
              }
              
              const asigUuid = typeof asig === 'string' ? asig : (asig.uuid || asig.id);
              const subject = subjects.find(s => s.id === asigUuid);
              
              if (subject) {
                const result = {
                  id: subject.id,
                  uuid: subject.id,
                  name: subject.name,
                  autonomous_community: asig.autonomous_community || subject.autonomous_community,
                  is_regulated: asig.is_regulated || subject.is_regulated,
                  course_level: asig.course_level || subject.course_level
                };
                return result;
              }
              return null;
            }).filter(Boolean);
          }

          const data = {
            ...initialProgramacion,
            ...response.data,
            nivel_educativo: response.data.nivel_educativo ? 
              { id: response.data.nivel_educativo, name: LEVELS.find(l => l.id === response.data.nivel_educativo)?.name } : 
              { id: '', name: '' },
            comunidad_autonoma: response.data.comunidad_autonoma || COMUNIDADES[0].name,
            ano: response.data.ano || 2025,
            idioma: response.data.idioma || 'Español'
          };

          data.asignaturas = asignaturas;
          
          setProgramacion(data);
          setLoading(false);
        })
        .catch(err => {
          setError('Error al cargar la programación');
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (programacion?.comunidad_autonoma?.id && programacion?.nivel_educativo?.id) {
      SubjectService.getLomloeSubjectsByRegionAndLevel(programacion.comunidad_autonoma.id, programacion.nivel_educativo.id)
        .then(response => {
          setAsignaturas(response.data);
          
          if (isInfantilLevel && response.data.length >= 3) {
            setAsignaturas(response.data.slice(0, 3));
          }
        })
        .catch(() => {
          setAsignaturas([]);
        });
    }
  }, [programacion?.comunidad_autonoma?.id, programacion?.nivel_educativo?.id, isInfantilLevel]);

  useEffect(() => {
    if (programacion?.numero_situaciones_aprendizaje) {
      const maxAllowed = getMaxSituacionesAllowed(userPlan);
      
      if (programacion.numero_situaciones_aprendizaje > maxAllowed) {
        setShowPlanAlert(true);
        setProgramacion(prev => ({
          ...prev,
          numero_situaciones_aprendizaje: maxAllowed
        }));
      }
    }
  }, [programacion?.numero_situaciones_aprendizaje, userPlan]);

  useEffect(() => {
    setSearchParams({ paso: activeStep });
  }, [activeStep, setSearchParams]);

  const updateLoadingState = (isLoading) => {
    setIsAnyLoading(isLoading);
  };

  const isProcessing = saving || finalizando || isGeneratingAI || isAnyLoading;

  const handleStepChange = (step) => {
    if (isProcessing) return;
    setActiveStep(step);
  };

  const saveCurrentStep = async () => {
    if (!id) {
      try {
        const response = await ProgramacionDidacticaService.createProgramacion({
          ...programacion,
          estado: 'borrador',
          ano: programacion.ano || 2025,
          nivel_educativo: typeof programacion.nivel_educativo === 'object' ? 
            programacion.nivel_educativo.id : programacion.nivel_educativo,
          idioma: programacion.idioma || 'Español'
        });
        navigate(`/programaciones/editar/${response.data.id}?paso=${activeStep}`);
        return;
      } catch (err) {
        throw err;
      }
    }

    setSaving(true);
    try {
      let stepName = STEPS[activeStep].toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/ /g, '_');
      
      if (stepName === 'unidades_didacticas') {
        stepName = 'unidades_didacticas';
      }
      
      let stepData;
      
      if (stepName === 'informacion_basica') {
        stepData = {
          informacion_basica: {
            titulo: programacion.titulo || '',
            ano: programacion.ano || 2025,
            comunidad_autonoma: programacion.comunidad_autonoma || '',
            nivel_educativo: typeof programacion.nivel_educativo === 'object' ? 
              programacion.nivel_educativo.id : programacion.nivel_educativo,
            asignaturas: (programacion.asignaturas || []).map(asig => asig.uuid),
            centro_educativo: programacion.centro_educativo || '',
            numero_situaciones_aprendizaje: programacion.numero_situaciones_aprendizaje || 12,
            idioma: programacion.idioma || 'Español'
          }
        };
      } else if (stepName === 'unidades_didacticas') {
        const unidades = (programacion.unidades_didacticas || []).map((unidad, index) => ({
          numero: index + 1,
          titulo: unidad.titulo || '',
          temporalizacion: unidad.temporalizacion || '',
          justificacion: unidad.justificacion || `Justificación para la unidad didáctica ${index + 1}`,
          tabla: unidad.tabla || ''
        }));
        
        stepData = {
          unidades_didacticas: unidades,
          plan_anual_situaciones: programacion.plan_anual_situaciones || ''
        };
      } else {
        stepData = {
          [stepName]: programacion[stepName] || {}
        };
      }

      await ProgramacionDidacticaService.updateProgramacionStep(id, stepName, stepData);
    } catch (err) {
      throw err;
    } finally {
      setSaving(false);
    }
  };

  const handleNext = async () => {
    if (isProcessing) return;
    
    try {
      if (activeStep === 0) {
        const hasErrors = !programacion.titulo || 
                         programacion.titulo === 'Nueva Programación Didáctica' ||
                         !programacion.nivel_educativo?.id ||
                         !programacion.asignaturas?.length;
        
        if (hasErrors) {
          setError('Por favor, completa todos los campos requeridos correctamente');
          return;
        }
      }

      await saveCurrentStep();
      setError(null);
      setActiveStep((prev) => Math.min(prev + 1, STEPS.length - 1));
    } catch (err) {
      setError('Error al guardar. Por favor, inténtalo de nuevo.');
    }
  };

  const handleBack = () => {
    if (isProcessing) return;
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const handleFinish = async () => {
    if (isProcessing) return;
    
    try {
      setFinalizando(true);
      
      setProgreso(0);
      const intervalo = setInterval(() => {
        setProgreso(prevProgress => {
          const newProgress = prevProgress + (100 / 120);
          return newProgress >= 100 ? 100 : newProgress;
        });
      }, 1000);
      
      await saveCurrentStep();
      
      const response = await ProgramacionDidacticaService.generateMarkdown(id);
      
      const preparedProgramacion = {
        ...programacion,
        estado: 'completada',
        contenido_markdown: response.data.contenido_markdown,
        ano: programacion.ano || 2025,
        nivel_educativo: typeof programacion.nivel_educativo === 'object' ? 
          programacion.nivel_educativo.id : programacion.nivel_educativo,
        idioma: programacion.idioma || 'Español'
      };

      Object.keys(preparedProgramacion).forEach(key => {
        if (preparedProgramacion[key] === null || preparedProgramacion[key] === undefined) {
          delete preparedProgramacion[key];
        }
      });
      
      if (preparedProgramacion.unidades_didacticas && !Array.isArray(preparedProgramacion.unidades_didacticas)) {
        preparedProgramacion.unidades_didacticas = [];
      }
      
      if (preparedProgramacion.unidades_didacticas && Array.isArray(preparedProgramacion.unidades_didacticas)) {
        preparedProgramacion.unidades_didacticas = preparedProgramacion.unidades_didacticas.map(unidad => ({
          ...unidad,
          numero: unidad.numero || 0,
          titulo: unidad.titulo || '',
          temporalizacion: unidad.temporalizacion || '',
          justificacion: unidad.justificacion || `Justificación para la unidad didáctica "${unidad.titulo || 'sin título'}"`,
          tabla: unidad.tabla || ''
        }));
      }

      await ProgramacionDidacticaService.updateProgramacion(id, preparedProgramacion);
      
      clearInterval(intervalo);
      setProgreso(100);
      
      setTimeout(() => {
        setFinalizando(false);
        navigate(`/ia/programaciones/finalizada/${id}`);
      }, 500);
    } catch (err) {
      setError('Error al finalizar la programación: ' + (err.response?.data?.detail || err.message));
      setFinalizando(false);
    }
  };

  const getStepContent = (step) => {
    const commonProps = {
      programacion,
      setProgramacion,
      userPlan,
      setIsGeneratingAI,
      updateLoadingState,
      id
    };

    switch (step) {
      case 0:
        return (
          <>
            <InformacionBasica 
              {...commonProps}
              nivelesEducativos={nivelesEducativos}
              asignaturas={asignaturas}
              maxSituaciones={getMaxSituacionesAllowed(userPlan)}
            />
          </>
        );
      case 1:
        return <Introduccion {...commonProps} />;
      case 2:
        return <MarcoLegislativo {...commonProps} />;
      case 3:
        return <Contexto {...commonProps} />;
      case 4:
        return <ElementosTransversales {...commonProps} />;
      case 5:
        return <Metodologia {...commonProps} />;
      case 6:
        return <Evaluacion {...commonProps} />;
      case 7:
        return <AtencionDiversidad {...commonProps} />;
      case 8:
        return (
          <>
            <SituacionesAprendizaje 
              {...commonProps} 
              maxSituaciones={getMaxSituacionesAllowed(userPlan)}
            />
          </>
        );
      case 9:
        return <Conclusiones {...commonProps} />;
      case 10:
        return <Bibliografia {...commonProps} />;
      default:
        return null;
    }
  };

  const toggleStepper = () => {
    setStepperExpanded(!stepperExpanded);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Alert severity="error">{error}</Alert>
        <Button 
          variant="contained" 
          sx={{ mt: 2 }}
          onClick={() => navigate('/ia/programaciones')}
        >
          Volver a Programaciones
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ p: 4 }}>
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate('/ia/programaciones')}
          sx={{ mb: 2 }}
          disabled={isProcessing}
        >
          Volver a Mis Programaciones
        </Button>
        
        <Typography variant="h4" gutterBottom>
          {programacion.titulo || 'Nueva Programación Didáctica'}
        </Typography>
        
        {!isLargeScreen && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 2,
            p: 1.5,
            border: `1px dashed ${theme.palette.primary.main}`,
            borderRadius: 1,
            backgroundColor: `${theme.palette.primary.light}15`,
            transition: 'all 0.3s ease'
          }}>
            <Typography variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 'medium' }}>
              Paso {activeStep + 1}: {STEPS[activeStep]}
            </Typography>
            <Button 
              onClick={toggleStepper}
              variant="outlined"
              size="small"
              color="primary"
              endIcon={stepperExpanded ? <ExpandLess /> : <ExpandMore />}
              sx={{ 
                ml: 1,
                animation: stepperExpanded ? 'none' : 'pulse 1.5s infinite',
                '@keyframes pulse': {
                  '0%': { boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.4)' },
                  '70%': { boxShadow: '0 0 0 6px rgba(25, 118, 210, 0)' },
                  '100%': { boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)' }
                }
              }}
            >
              {stepperExpanded ? 'Ocultar pasos' : 'Ver todos los pasos'}
            </Button>
          </Box>
        )}
        
        <Collapse in={isLargeScreen || stepperExpanded}>
          <Box sx={{ 
            overflowX: isMobile ? 'auto' : 'visible',
            '&::-webkit-scrollbar': { height: '8px' },
            '&::-webkit-scrollbar-thumb': { backgroundColor: '#bdbdbd', borderRadius: '4px' },
            mb: 4
          }}>
            <Stepper 
              activeStep={activeStep} 
              orientation={isMobile ? 'vertical' : 'horizontal'}
              alternativeLabel={!isMobile}
            >
              {STEPS.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => handleStepChange(index)} disabled={isProcessing}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Collapse>

        {finalizando && (
          <Box sx={{ my: 4, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom color="primary">
              Terminando de generar tu Programación Didáctica
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              Esto puede tardar unos minutos. No cierres la ventana.
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={progreso} 
              sx={{ height: 10, borderRadius: 5, mb: 1 }}
            />
            <Typography variant="body2" color="text.secondary">
              {Math.round(progreso)}% completado
            </Typography>
          </Box>
        )}

        {isGeneratingAI && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Generando contenido con inteligencia artificial. Por favor, espera un momento...
          </Alert>
        )}
        
        {isAnyLoading && !isGeneratingAI && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Procesando datos. Por favor, espera un momento...
          </Alert>
        )}

        <Box>
          {!finalizando && getStepContent(activeStep)}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0 || isProcessing}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Atrás
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button 
              onClick={activeStep === STEPS.length - 1 ? handleFinish : handleNext}
              variant="contained"
              disabled={isProcessing}
            >
              {saving ? (
                <CircularProgress size={24} />
              ) : finalizando ? (
                <CircularProgress size={24} />
              ) : isProcessing ? (
                <CircularProgress size={24} />
              ) : activeStep === STEPS.length - 1 ? (
                'Finalizar'
              ) : (
                'Siguiente'
              )}
            </Button>
          </Box>
        </Box>
    </Container>
  );
};

export default ProgramacionWizard; 