import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  CircularProgress,
  Alert,
  Divider,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ProgramacionDidacticaService from '../../services/ProgramacionDidacticaService';
import ReactMarkdown from 'react-markdown';
import { ArrowBack, PictureAsPdf, Description } from '@mui/icons-material';
import SettingsService from '../../services/SettingsService';
import { canDownloadProgramaciones, isLimitedPlan } from '../../utils/PlanUtils';
import UpgradeAlert from '../subscription/UpgradeAlert';
import jsPDF from 'jspdf';

const ProgramacionFinalizada = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [programacion, setProgramacion] = useState(null);
  const [contenido, setContenido] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userPlan, setUserPlan] = useState('');
  const [showPlanAlert, setShowPlanAlert] = useState(false);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [showDocxDialog, setShowDocxDialog] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    SettingsService.getProfileInfo()
      .then(response => {
        setUserPlan(response.data.plan || '');
      })
      .catch(err => {
        console.error('Error al cargar información del perfil:', err);
      });
    
    fetchProgramacion();
  }, [id]);

  useEffect(() => {
    if (contentRef.current && isLimitedPlan(userPlan)) {
      const preventCopy = (e) => {
        e.preventDefault();
        setShowPlanAlert(true);
      };
      
      contentRef.current.addEventListener('copy', preventCopy);
      contentRef.current.addEventListener('cut', preventCopy);
      contentRef.current.addEventListener('paste', preventCopy);
      contentRef.current.addEventListener('contextmenu', preventCopy);
      
      return () => {
        if (contentRef.current) {
          contentRef.current.removeEventListener('copy', preventCopy);
          contentRef.current.removeEventListener('cut', preventCopy);
          contentRef.current.removeEventListener('paste', preventCopy);
          contentRef.current.removeEventListener('contextmenu', preventCopy);
        }
      };
    }
  }, [userPlan, contentRef.current]);

  const fetchProgramacion = async () => {
    try {
      setLoading(true);
      const response = await ProgramacionDidacticaService.getProgramacionById(id);
      setProgramacion(response.data);
      
      if (!response.data.contenido_markdown) {
        const markdownResponse = await ProgramacionDidacticaService.generateMarkdown(id);
        setContenido(markdownResponse.data.contenido_markdown.replace(/\n/g,"<br />"));
        
        await ProgramacionDidacticaService.updateProgramacion(id, {
          ...response.data,
          contenido_markdown: markdownResponse.data.contenido_markdown
        });
      } else {
        setContenido(response.data.contenido_markdown);
      }
    } catch (err) {
      console.error('Error al cargar la programación:', err);
      setError('No se pudo cargar la programación');
    } finally {
      setLoading(false);
    }
  };

  const generatePdfFromHtml = async () => {
    if (!contentRef.current) return;
    
    setGeneratingPdf(true);
    try {
      const contentElement = contentRef.current;
      const doc = new jsPDF('p', 'mm', 'a4');
      
      // Clone the content to avoid modifying the visible DOM
      const clonedContent = contentElement.cloneNode(true);
      const tempContainer = document.createElement('div');
      tempContainer.appendChild(clonedContent);
      tempContainer.style.position = 'absolute';
      tempContainer.style.left = '-9999px';
      document.body.appendChild(tempContainer);
      
      // Process and clean up content for better PDF rendering
      const processContent = () => {
        // Fix image paths and add max-width
        const images = clonedContent.querySelectorAll('img');
        images.forEach(img => {
          // Ensure image has src attribute
          if (!img.src && img.getAttribute('data-src')) {
            img.src = img.getAttribute('data-src');
          }
          
          // Set max dimensions
          img.style.maxWidth = '90%';
          img.style.maxHeight = '150mm';
          img.style.pageBreakInside = 'avoid';
          img.style.display = 'block';
          img.style.margin = '8pt auto';
        });
        
        // Process tables for better display
        const tables = clonedContent.querySelectorAll('table');
        tables.forEach(table => {
          table.style.fontSize = '8pt';
          // Add a div wrapper to help with page breaks
          const wrapper = document.createElement('div');
          wrapper.style.pageBreakInside = 'avoid';
          wrapper.style.marginTop = '8pt';
          wrapper.style.marginBottom = '8pt';
          table.parentNode.insertBefore(wrapper, table);
          wrapper.appendChild(table);
        });
        
        // Add proper formatting to headers
        const headers = clonedContent.querySelectorAll('h1, h2, h3, h4, h5, h6');
        headers.forEach(header => {
          header.style.fontFamily = '"Times New Roman", Times, serif';
          header.style.marginTop = '6pt';
          header.style.marginBottom = '3pt';
          
          if (header.tagName === 'H1') {
            header.style.fontSize = '11pt';
            header.style.fontWeight = 'bold';
          } else if (header.tagName === 'H2') {
            header.style.fontSize = '10pt';
            header.style.fontWeight = 'bold';
          } else if (header.tagName === 'H3') {
            header.style.fontSize = '9pt';
            header.style.fontWeight = 'bold';
          }
          
          // Prevent orphan headers
          if (header.nextElementSibling) {
            header.nextElementSibling.style.pageBreakBefore = 'avoid';
          }
        });
        
        // Adjust paragraph styles
        const paragraphs = clonedContent.querySelectorAll('p');
        paragraphs.forEach(p => {
          p.style.fontFamily = '"Times New Roman", Times, serif';
          p.style.fontSize = '8pt';
          p.style.margin = '0 0 3pt 0';
          p.style.lineHeight = '1.2';
        });
        
        // Format lists
        const lists = clonedContent.querySelectorAll('ul, ol');
        lists.forEach(list => {
          list.style.fontFamily = '"Times New Roman", Times, serif';
          list.style.fontSize = '8pt';
          list.style.marginTop = '0';
          list.style.marginBottom = '3pt';
        });
        
        const listItems = clonedContent.querySelectorAll('li');
        listItems.forEach(li => {
          li.style.fontFamily = '"Times New Roman", Times, serif';
          li.style.fontSize = '8pt';
          li.style.marginBottom = '1pt';
        });
      };
      
      processContent();
      
      // Add CSS styles for better PDF formatting
      const styleElement = document.createElement('style');
      styleElement.textContent = `
        body {
          font-family: "Times New Roman", Times, serif;
          font-size: 8pt;
          line-height: 1.2;
          color: #000000;
        }
        h1, h2, h3 {
          font-family: "Times New Roman", Times, serif;
          page-break-after: avoid;
          page-break-inside: avoid;
        }
        h1 { font-size: 11pt; margin-top: 6pt; margin-bottom: 3pt; font-weight: bold; }
        h2 { font-size: 10pt; margin-top: 5pt; margin-bottom: 2pt; font-weight: bold; }
        h3 { font-size: 9pt; margin-top: 4pt; margin-bottom: 2pt; font-weight: bold; }
        p { 
          font-family: "Times New Roman", Times, serif;
          font-size: 8pt;
          margin-bottom: 3pt; 
          page-break-inside: avoid; 
          line-height: 1.2;
        }
        table { 
          width: 100%; 
          border-collapse: collapse; 
          page-break-inside: avoid;
          margin-bottom: 6pt;
          font-family: "Times New Roman", Times, serif;
          font-size: 8pt;
        }
        th, td { 
          border: 1px solid #000; 
          padding: 2pt; 
          text-align: left; 
        }
        th { 
          background-color: #f2f2f2; 
        }
        ul, ol { 
          font-family: "Times New Roman", Times, serif;
          page-break-inside: avoid;
          margin-bottom: 6pt;
          padding-left: 12pt;
          font-size: 8pt;
        }
        li {
          font-family: "Times New Roman", Times, serif;
          font-size: 8pt;
          margin-bottom: 1pt;
        }
        img {
          max-width: 90%;
          height: auto;
        }
      `;
      clonedContent.appendChild(styleElement);
      
      // Apply better styling for PDF output
      clonedContent.style.padding = '10mm';
      clonedContent.style.backgroundColor = 'white';
      clonedContent.style.fontFamily = '"Times New Roman", Times, serif';
      clonedContent.style.fontSize = '8pt';
      clonedContent.style.width = '170mm';
      
      // Create a standard title page
      const titlePage = document.createElement('div');
      titlePage.innerHTML = `
        <div style="text-align: center; margin-top: 60px; font-family: 'Times New Roman', Times, serif;">
          <h1 style="font-size: 14pt; margin-bottom: 20px; font-weight: bold;">${programacion?.titulo || 'Programación Didáctica'}</h1>
          <p style="margin-bottom: 10px; font-size: 11pt;">4Docentes</p>
          <p style="margin-bottom: 10px; font-size: 10pt;">Fecha: ${new Date().toLocaleDateString()}</p>
        </div>
      `;
      clonedContent.insertBefore(titlePage, clonedContent.firstChild);
      
      // Add page break after title page
      const pageBreak = document.createElement('div');
      pageBreak.style.pageBreakAfter = 'always';
      titlePage.appendChild(pageBreak);
      
      // Generate PDF with better scaling
      await doc.html(clonedContent, {
        callback: function(doc) {
          // Remove header and footer code section
          // Just save the PDF directly
          
          // Save the PDF with a meaningful name
          const filename = programacion?.titulo ? 
            `programacion_${programacion.titulo.replace(/\s+/g, '_')}.pdf` : 
            `programacion_${id}.pdf`;
          
          doc.save(filename);
          
          // Clean up
          document.body.removeChild(tempContainer);
          setGeneratingPdf(false);
        },
        x: 10,
        y: 10,
        width: 190,
        windowWidth: 800,
        margin: [10, 10, 10, 10],
        autoPaging: true,
        html2canvas: {
          scale: 0.3, // Drastically reduced scale
          useCORS: true
        }
      });
    } catch (error) {
      console.error('Error generando PDF:', error);
      setGeneratingPdf(false);
      setError('No se pudo generar el PDF. Intente nuevamente o descargue en formato DOCX.');
    }
  };

  const handleDownloadClick = (format) => {
    if (!canDownloadProgramaciones(userPlan)) {
      setShowPlanAlert(true);
      return;
    }
    
    if (format === 'pdf') {
      generatePdfFromHtml();
    } else if (format === 'docx') {
      setShowDocxDialog(true);
    }
  };

  const handleDocxConfirm = () => {
    setShowDocxDialog(false);
    ProgramacionDidacticaService.downloadAsDocx(id);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Alert severity="error">{error}</Alert>
        <Button
          variant="contained" 
          sx={{ mt: 2 }}
          onClick={() => navigate('/ia/programaciones')}
        >
          Volver a Programaciones
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4, p: 3 }}>
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate('/ia/programaciones')}
          sx={{ mb: 2 }}
        >
          Volver a Mis Programaciones
        </Button>

        {showPlanAlert && (
          <UpgradeAlert 
            message="Tu plan actual no permite descargar ni copiar el contenido de las programaciones finalizadas."
            onClose={() => setShowPlanAlert(false)}
          />
        )}

        <ButtonGroup variant="contained" sx={{ mb: 2, ml: 2 }}>
          <Button
            startIcon={<PictureAsPdf />}
            onClick={() => handleDownloadClick('pdf')}
            disabled={generatingPdf}
          >
            {generatingPdf ? 'Generando PDF...' : 'Descargar PDF'}
          </Button>
          <Button
            startIcon={<Description />}
            onClick={() => handleDownloadClick('docx')}
            title="En fase experimental. La descarga en formato Word puede sufrir problemas de formato."
          >
            Descargar DOCX
          </Button>
        </ButtonGroup>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h4" gutterBottom>
            {programacion.titulo}
          </Typography>
        </Box>

        <Divider sx={{ mb: 3 }} />

        <Box
          ref={contentRef}
          sx={{ 
            p: 3, 
            bgcolor: 'background.paper', 
            borderRadius: 1,
            minHeight: '500px',
            userSelect: isLimitedPlan(userPlan) ? 'none' : 'auto',
            '& img': {
              maxWidth: '100%',
              height: 'auto'
            },
            '& h1': {
              fontSize: '2rem',
              mb: 2,
              color: 'primary.main'
            },
            '& h2': {
              fontSize: '1.5rem',
              mt: 3,
              mb: 2,
              color: 'secondary.main'
            },
            '& h3': {
              fontSize: '1.25rem',
              mt: 2,
              mb: 1,
              color: 'text.primary'
            },
            '& p': {
              mb: 2,
              lineHeight: 1.6
            },
            '& ul, & ol': {
              mb: 2,
              pl: 3
            },
            '& li': {
              mb: 1
            }
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: contenido }} />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/ia/programaciones')}
          >
            Volver
          </Button>
          <ButtonGroup variant="contained">
            <Button
              startIcon={<PictureAsPdf />}
              onClick={() => handleDownloadClick('pdf')}
              disabled={generatingPdf}
            >
              {generatingPdf ? 'Generando PDF...' : 'Descargar PDF'}
            </Button>
            <Button
              startIcon={<Description />}
              onClick={() => handleDownloadClick('docx')}
              title="En fase experimental. La descarga en formato Word puede sufrir problemas de formato."
            >
              Descargar DOCX
            </Button>
          </ButtonGroup>
        </Box>

        <Dialog
          open={showDocxDialog}
          onClose={() => setShowDocxDialog(false)}
        >
          <DialogTitle>Descarga en formato Word</DialogTitle>
          <DialogContent>
            <Typography>
              Esta función se encuentra en fase experimental. La descarga en formato Word puede sufrir problemas de formato.
              ¿Desea continuar con la descarga?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDocxDialog(false)}>Cancelar</Button>
            <Button onClick={handleDocxConfirm} variant="contained">
              Descargar
            </Button>
          </DialogActions>
        </Dialog>
    </Container>
  );
};

export default ProgramacionFinalizada; 