import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Chip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Alert
} from '@mui/material';
import { AddTwoTone as AddIcon, EditTwoTone as EditIcon, DeleteTwoTone as DeleteIcon, VisibilityTwoTone as VisibilityIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ProgramacionDidacticaService from '../../services/ProgramacionDidacticaService';
import SettingsService from '../../services/SettingsService';
import { LEVELS } from './utils';
import { getMaxProgramacionesAllowed, canDeleteProgramaciones } from '../../utils/PlanUtils';
import UpgradeAlert from '../subscription/UpgradeAlert';

const ProgramacionesList = () => {
  const [programaciones, setProgramaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [programacionToDelete, setProgramacionToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [userPlan, setUserPlan] = useState('');
  const [showLimitAlert, setShowLimitAlert] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    SettingsService.getProfileInfo()
      .then(response => {
        setUserPlan(response.data.plan || '');
      })
      .catch(err => {
        console.error('Error al cargar información del perfil:', err);
      });
    
    fetchProgramaciones();
  }, []);

  const fetchProgramaciones = async () => {
    setLoading(true);
    try {
      const response = await ProgramacionDidacticaService.getProgramaciones();
      setProgramaciones(response.data);
    } catch (err) {
      console.error('Error al cargar las programaciones:', err);
      setError('No se pudieron cargar las programaciones. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNew = async () => {
    try {
      // Verificar límite de programaciones
      const maxAllowed = getMaxProgramacionesAllowed(userPlan);
      if (programaciones.length >= maxAllowed) {
        setShowLimitAlert(true);
        return;
      }
      
      setLoading(true);
      const response = await ProgramacionDidacticaService.createProgramacion({
        titulo: "Nueva Programación Didáctica",
        numero_situaciones_aprendizaje: 12,
      });
      navigate(`/ia/programaciones/wizard/${response.data.id}`);
    } catch (err) {
      console.error('Error al crear nueva programación:', err);
      setError('No se pudo crear la programación. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (programacion) => {
    if (programacion.estado === 'completada') {
      navigate(`/ia/programaciones/finalizada/${programacion.id}`);
    } else {
      navigate(`/ia/programaciones/wizard/${programacion.id}`);
    }
  };

  const handleDeleteClick = (programacion) => {
    // Verificar si puede eliminar según el plan
    if (!canDeleteProgramaciones(userPlan)) {
      setShowLimitAlert(true);
      return;
    }
    
    setProgramacionToDelete(programacion);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!programacionToDelete) return;
    
    setDeleting(true);
    try {
      await ProgramacionDidacticaService.deleteProgramacion(programacionToDelete.id);
      setProgramaciones(programaciones.filter(p => p.id !== programacionToDelete.id));
      setDeleteDialogOpen(false);
      setProgramacionToDelete(null);
    } catch (err) {
      console.error('Error al eliminar la programación:', err);
      setError('No se pudo eliminar la programación. Por favor, inténtalo de nuevo.');
    } finally {
      setDeleting(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setProgramacionToDelete(null);
  };

  const getEstadoChipColor = (estado) => {
    switch (estado) {
      case 'borrador':
        return 'warning';
      case 'completada':
        return 'success';
      default:
        return 'default';
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4, p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Programaciones Didácticas
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateNew}
          >
            Nueva Programación
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        {showLimitAlert && (
          <UpgradeAlert 
            message={
              programaciones.length >= getMaxProgramacionesAllowed(userPlan) - 1
                ? `Tu plan actual limita el número de programaciones didácticas a ${getMaxProgramacionesAllowed(userPlan)}.`
                : "Tu plan actual no permite eliminar programaciones didácticas una vez creadas."
            }
            onClose={() => setShowLimitAlert(false)}
          />
        )}

        {programaciones.length === 0 ? (
          <Box sx={{ textAlign: 'center', py: 5 }}>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              No tienes programaciones didácticas
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Crea tu primera programación didáctica haciendo clic en el botón "Nueva Programación"
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {programaciones.map((programacion) => (
              <Grid item xs={12} sm={6} md={4} key={programacion.id}>
                <Card>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <Typography variant="h6" component="div" noWrap sx={{ maxWidth: '70%' }}>
                        {programacion.titulo}
                      </Typography>
                      <Chip 
                        label={programacion.estado} 
                        color={getEstadoChipColor(programacion.estado)}
                        size="small"
                      />
                    </Box>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      {programacion.nivel_educativo 
                        ? LEVELS.find(level => level.id === programacion.nivel_educativo)?.name || programacion.nivel_educativo
                        : 'Sin nivel educativo'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {programacion.asignaturas && programacion.asignaturas.length > 0
                        ? programacion.asignaturas.map(asignatura => asignatura.name).join(', ')
                        : 'Sin asignatura'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {programacion.ano || 'Sin año académico'}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      startIcon={programacion.estado === 'completada' ? <VisibilityIcon /> : <EditIcon />}
                      onClick={() => handleEdit(programacion)}
                    >
                      {programacion.estado === 'completada' ? 'Ver' : 'Editar'}
                    </Button>
                    {canDeleteProgramaciones(userPlan) && (
                      <IconButton 
                        size="small" 
                        color="error"
                        onClick={() => handleDeleteClick(programacion)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Eliminar programación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar la programación "{programacionToDelete?.titulo}"? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} disabled={deleting}>
            Cancelar
          </Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            disabled={deleting}
            startIcon={deleting ? <CircularProgress size={20} /> : null}
          >
            {deleting ? 'Eliminando...' : 'Eliminar'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProgramacionesList; 