import React, {useEffect, useRef, useState} from 'react';
import {
    SendTwoTone,
    Menu,
    Close,
    Add,
    DeleteTwoTone,
    AttachFileTwoTone,
    ImageTwoTone,
    KeyboardArrowDownTwoTone, AttachmentTwoTone
} from "@mui/icons-material";
import {
    Alert,
    Card,
    IconButton,
    List,
    ListItem,
    Paper,
    styled,
    Box as MuiBox,
    Button,
    DialogTitle,
    Dialog, DialogContent, DialogContentText, DialogActions, Chip
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import {useTheme} from "@mui/material/styles";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import Logo from "../../../components/Logo";
import AIService from "../../../services/AIService";
import {LoadingButton} from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import IAFeedback from "../feedback/IAFeedback";
import SubscriptionPopup from "../../subscription/popup";
import SettingsService from "../../../services/SettingsService";

const ChatContainer = styled(Card)(({theme}) => ({
    height: "85vh",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f5f5f5",
    borderRadius: "12px",
    overflow: "hidden",
    position: "relative"  // This is important for containing the drawer
}));

const DrawerContainer = styled(MuiBox, {
    shouldForwardProp: prop => prop !== 'open'
})(({theme, open}) => ({
    flexShrink: 0,
    width: '280px',
    backgroundColor: '#fff',
    zIndex: 1300,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create(['transform', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    transform: open ? 'translateX(0)' : 'translateX(-100%)',
    [theme.breakpoints.up('md')]: {
        position: 'static',
        transform: 'none',
        width: open ? '280px' : '0px',
    },
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
}));

const ChatContent = styled(Box)(({theme, open}) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#ffffff',
    position: 'relative',
    transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
        marginLeft: open ? '280px' : '0px',
    },
}));

const MessageList = styled(List)(({theme}) => ({
    flex: 1,
    overflow: 'auto',
    backgroundColor: '#f5f5f5',
    "&::-webkit-scrollbar": {
        width: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#bdbdbd",
        borderRadius: "3px"
    }
}));

const ConversationList = styled(List)({
    flex: 1,
    overflow: 'auto',
    padding: 0,
    backgroundColor: '#ffffff'
});

const EmptyState = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    textAlign: 'center',
    height: '100%',
    gap: theme.spacing(2)
}));

const MessageBubble = styled(Paper, {
    shouldForwardProp: prop => prop !== 'isUser'
})(({isUser, theme}) => ({
    padding: "12px 16px",
    borderRadius: isUser ? "18px 18px 0 18px" : "18px 18px 18px 0",
    backgroundColor: isUser ? theme.palette.primary.main : "#ffffff",
    color: isUser ? "#ffffff" : "#000000",
    wordWrap: "break-word",
    "p": {
        marginBottom: 0,
        marginTop: 0,
    }
}));

const InputContainer = styled(Box)(({theme}) => ({
    padding: theme.spacing(2),
    backgroundColor: "#ffffff",
    borderTop: "1px solid #e0e0e0"
}));

const DrawerHeader = styled(Box)(({theme}) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
}));

const ConversationItem = styled(ListItem)(({theme, selected}) => ({
    cursor: "pointer",
    backgroundColor: selected ? theme.palette.action.selected : "transparent",
    "&:hover": {
        backgroundColor: theme.palette.action.hover
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2)
}));

const ChatBot = () => {
    const [messages, setMessages] = useState([{
        text: "¡Hola! Soy tu asistente en la docencia. ¿Cómo puedo ayudarte?",
        isUser: false,
    }]);
    const [threadId, setThreadId] = useState(null);
    const [input, setInput] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [conversationToDelete, setConversationToDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const messagesEndRef = useRef(null);
    const theme = useTheme();

    useEffect(() => {
        if (!isTyping) {
            AIService.getPreviousConversations().then((response) => {
                setConversations(response.data.conversations);
                response.data.conversations.forEach((conversation) => {
                    if (conversation.thread_id === threadId) {
                        setSelectedConversation(conversation);
                    }
                })
            }).catch(() => {
                setErrorMessage("Algo ha ido mal. Por favor, inténtalo de nuevo")
            })
        }
        scrollToBottom();
    }, [threadId, isTyping]);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
        setShowScrollButton(!bottom);
    };

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        const validFiles = files.filter(file => {
            const validTypes = ['.pdf', '.pptx', '.doc', '.docx'];
            const isValidType = validTypes.some(type => file.name.toLowerCase().endsWith(type));
            const isValidSize = file.size <= 5 * 1024 * 1024; // 5MB
            return isValidType && isValidSize;
        });

        if (validFiles.length !== files.length) {
            setErrorMessage('Algunos ficheros no han sido aceptados. Asegúrate de que su tamaño sea inferior a 5MB y tienen formato PDF, PPTX, DOC o DOCX.');
        }

        setAttachments(prev => [...prev, ...validFiles]);
        event.target.value = '';
    };

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        const validFiles = files.filter(file => {
            const isImage = file.type.startsWith('image/');
            const isValidSize = file.size <= 5 * 1024 * 1024; // 5MB
            return isImage && isValidSize;
        });

        if (validFiles.length !== files.length) {
            setErrorMessage('Algunos ficheros no han sido aceptados. Asegúrate de que el tamaño de las imágenes sea inferior a 5MB.');
        }

        setAttachments(prev => [...prev, ...validFiles]);
        event.target.value = '';
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleConversationSelect = (conversation) => {
        setIsLoading(true);
        setDrawerOpen(false);
        setMessages([]);
        setSelectedConversation(conversation);
        setThreadId(conversation.thread_id);
        AIService.getMessagesByConversationID(conversation.id).then((response) => {
            setMessages(response.data.conversation);
        }).catch(() => {
            setErrorMessage("Algo ha ido mal. Por favor, inténtalo de nuevo")
        })
            .finally(() => {
                    setIsLoading(false);
                }
            );

    };

    const handleNewChat = () => {
        setMessages([{
            text: "¡Hola! Soy tu asistente en la docencia. ¿Cómo puedo ayudarte?",
            isUser: false,
        }]);
        setThreadId(null);
        setSelectedConversation(null);
        setDrawerOpen(false);
    };

    const sendResponseToAI = async (formData) => {
        setIsTyping(true);
        SettingsService.getProfileInfo().then(response => {
            if (!response.data.is_subscription_active && isPopupOpen === null) {
                setIsPopupOpen(true);
            }
        })

        try {
            const response = await AIService.sendChatMessage(formData).catch(() => {
                setErrorMessage("Algo ha ido mal. Por favor, inténtalo de nuevo")
            });
            const conversation = response.data.conversation;
            AIService.getPreviousConversations().then((response) => {
                setConversations(response.data.conversations);
            }).catch(() => {
                setErrorMessage("Algo ha ido mal. Por favor, inténtalo de nuevo")
            });
            setThreadId(response.data.thread_id);
            return conversation[0].text;
        } finally {
            setIsTyping(false);
        }
    };

    const handleSend = async (e) => {
        e.preventDefault();
        const trimmedInput = attachments.length > 0 && !input.trim() ? "Archivo adjunto" : input.trim();

        if (trimmedInput || attachments.length > 0) {
            setInput("");

            const formData = new FormData();
            formData.append('message', trimmedInput);
            if (threadId) {
                formData.append('thread_id', threadId);
            }

            if (attachments.length > 0) {
                attachments.forEach(file => {
                    formData.append('files[]', file);
                });

                setAttachments([]);
            }
            setMessages(prev => [...prev, {
                text: trimmedInput,
                isUser: true,
                attachments: attachments.length > 0,
            }]);

            const response = await sendResponseToAI(formData);
            setMessages(prev => [...prev, {
                text: response,
                isUser: false,
            }])
            scrollToBottom();
        }
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleDeleteClick = (e, conversation) => {
        e.stopPropagation();
        setConversationToDelete(conversation);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (conversationToDelete) {
            setIsLoading(true);
            AIService.deleteConversation(conversationToDelete.id).then(() => {
                setConversations(conversations.filter(conv => conv.id !== conversationToDelete.id));
                if (selectedConversation?.id === conversationToDelete.id || !selectedConversation) {
                    handleNewChat();
                }
            }).catch(() => {
                setErrorMessage("Algo ha ido mal. Por favor, inténtalo de nuevo")
            }).finally(() => {
                    setDeleteDialogOpen(false);
                    setConversationToDelete(null);
                    setIsLoading(false);
                }
            );
        }

    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setConversationToDelete(null);
    };

    return (
        <Container width="100%" sx={{mt: 1, p: 0}}>
            {isPopupOpen === true && <SubscriptionPopup onClose={() => setIsPopupOpen(false)}/>}
            <ChatContainer elevation={3}>
                <DrawerContainer open={drawerOpen}>
                    <DrawerHeader>
                        <Typography variant="h6">Conversaciones</Typography>
                        <IconButton onClick={toggleDrawer} sx={{color: "white"}}>
                            <Close/>
                        </IconButton>
                    </DrawerHeader>

                    <Button
                        startIcon={<Add/>}
                        variant="contained"
                        onClick={handleNewChat}
                        sx={{m: 2}}
                    >
                        Nueva conversación
                    </Button>

                    {conversations.length > 0 ? (
                        <ConversationList>
                            {conversations.map((conversation) => (
                                <ConversationItem
                                    key={conversation.id}
                                    selected={selectedConversation?.id === conversation.id}
                                    onClick={() => handleConversationSelect(conversation)}
                                >
                                    <Box sx={{flex: 1, py: 1, textOverflow: "ellipsis", overflow: "hidden"}}>
                                        <Typography variant="subtitle1" noWrap>
                                            {conversation.title}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {new Date(conversation.modified_at).toLocaleString()}
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        onClick={(e) => handleDeleteClick(e, conversation)}
                                        size="small"
                                        sx={{
                                            ml: 1,
                                            color: 'error.main',
                                            '&:hover': {
                                                backgroundColor: 'error.light',
                                                color: 'error.dark',
                                            }
                                        }}
                                    >
                                        <DeleteTwoTone/>
                                    </IconButton>
                                </ConversationItem>
                            ))}
                        </ConversationList>
                    ) : (
                        <EmptyState>
                            <Typography variant="body1" color="textSecondary">
                                No hay conversaciones guardadas
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Inicia una nueva conversación para comenzar
                            </Typography>
                        </EmptyState>
                    )}
                </DrawerContainer>

                <ChatContent>
                    <Box sx={{
                        p: 2,
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        gap: 1
                    }}>
                        <IconButton
                            onClick={toggleDrawer}
                            sx={{color: "white", mr: 1}}
                        >
                            <Menu/>
                        </IconButton>
                        <Avatar sx={{backgroundColor: "white"}}>
                            <Box sx={{
                                width: "30px",
                                height: "30px",
                                display: "flex",
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Logo alt="4docent.es logo"/>
                            </Box>
                        </Avatar>
                        <Typography variant="h6">Asistente</Typography>
                    </Box>

                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100% - 64px)', // Subtract header height
                        backgroundColor: '#f5f5f5'
                    }}>
                        <MessageList onScroll={handleScroll}>
                            {messages.map((message, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        justifyContent: message.isUser ? "flex-end" : "flex-start",
                                        mb: 0,
                                    }}
                                >
                                    {message.isUser && message.attachments && (
                                        <Avatar sx={{backgroundColor: "white", mr: 1}}>
                                            <AttachmentTwoTone color="primary"/>
                                        </Avatar>
                                    )}
                                    <MessageBubble isUser={message.isUser} elevation={1}>
                                        <Markdown remarkPlugins={[remarkGfm]}>
                                            {message.text}
                                        </Markdown>
                                    </MessageBubble>
                                </ListItem>
                            ))}
                            {messages.length === 0 && <CircularProgress size={48} sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                            }}/>}
                            {isTyping && (
                                <ListItem
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        mb: 1
                                    }}
                                >
                                    <Avatar sx={{backgroundColor: "white", mr: 1}}>
                                        <Box sx={{
                                            width: "30px",
                                            height: "30px",
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <Logo alt={"4docent.es logo"}/>
                                        </Box>
                                    </Avatar>
                                    <MessageBubble isUser={false} elevation={1}>
                                        <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                                            <CircularProgress size={20}/>
                                            <Typography variant="body2">La IA está escribiendo...</Typography>
                                        </Box>
                                    </MessageBubble>
                                </ListItem>
                            )}
                            <Box width="30%" sx={{ml: 2}}>{threadId && messages.length > 0 &&
                                <IAFeedback tool="Asistente" input={{"messages": messages, "threadId": threadId}}
                                            output={messages[messages.length - 1]}/>}</Box>


                            <div ref={messagesEndRef}/>
                        </MessageList>

                        {showScrollButton && (
                            <IconButton
                                onClick={scrollToBottom}
                                sx={{
                                    position: 'absolute',
                                    bottom: 80,
                                    right: 16,
                                    backgroundColor: 'white',
                                    boxShadow: 2,
                                    '&:hover': {backgroundColor: 'grey.100'},
                                    zIndex: 1000,
                                }}
                            >
                                <KeyboardArrowDownTwoTone/>
                            </IconButton>
                        )}

                        <InputContainer>
                            <form onSubmit={handleSend} style={{display: "flex", flexDirection: "column", gap: "8px"}}>
                                {attachments.length > 0 && (
                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
                                        {attachments.map((file, index) => (
                                            <Chip
                                                key={index}
                                                label={file.name}
                                                onDelete={() => setAttachments(prev => prev.filter((_, i) => i !== index))}
                                                size="small"
                                            />
                                        ))}
                                    </Box>
                                )}
                                <Box sx={{display: "flex", gap: "8px", alignItems: "center"}}>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{display: 'none'}}
                                        onChange={handleFileUpload}
                                        accept=".pdf,.pptx,.doc,.docx"
                                        multiple
                                    />
                                    <input
                                        type="file"
                                        ref={imageInputRef}
                                        style={{display: 'none'}}
                                        onChange={handleImageUpload}
                                        accept=".gif,.jpeg,.jpg,.png,.webp"
                                        multiple
                                    />
                                    <IconButton
                                        size="small"
                                        onClick={() => fileInputRef.current?.click()}
                                        disabled={isTyping}
                                        color="primary"
                                    >
                                        <AttachFileTwoTone/>
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => imageInputRef.current?.click()}
                                        disabled={isTyping}
                                        color="primary"
                                    >
                                        <ImageTwoTone/>
                                    </IconButton>
                                    <TextField
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        variant="outlined"
                                        placeholder="Escribe tu mensaje..."
                                        value={input}
                                        onChange={handleInputChange}
                                        size="small"
                                        disabled={isTyping}
                                        autoComplete="off"
                                        sx={{backgroundColor: 'white'}}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault();
                                                handleSend(e);
                                            }
                                        }}
                                    />
                                    <IconButton
                                        type="submit"
                                        color="primary"
                                        disabled={(!input.trim() && attachments.length === 0) || isTyping}
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: "white",
                                            "&:hover": {backgroundColor: theme.palette.primary.light},
                                            "&:disabled": {backgroundColor: theme.palette.action.disabledBackground},
                                            width: "40px",
                                            height: "40px",
                                        }}
                                    >
                                        <SendTwoTone/>
                                    </IconButton>
                                </Box>
                            </form>
                        </InputContainer>
                    </Box>
                </ChatContent>
                <Snackbar open={!!errorMessage} autoHideDuration={5000} onClose={() => setErrorMessage(null)}>
                    <Alert onClose={() => setErrorMessage(null)} severity="error" sx={{width: '100%'}}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteCancel}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">
                        ¿De verdad quieres borrar esta conversación?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            Esta acción no se puede deshacer.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteCancel}>
                            Cancelar
                        </Button>
                        <LoadingButton onClick={handleDeleteConfirm} color="error" autoFocus variant="contained"
                                       loading={isLoading}>
                            Eliminar
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </ChatContainer>
        </Container>
    );
};

export default ChatBot;