import api from "./Api";

class AIService {
    getSDAs() {
        return api.get("/ai/sdas/");
    }

    saveSDA(data) {
        return api.post("/ai/sdas/", data);
    }

    generateSDA(data) {
        return api.post("/ai/sdas/generate/", data);
    }

    generateSituacionAprendizajeTitulo(data) {
        return api.post("/ai/situaciones-aprendizaje/generar-titulo/", data);
    }

    generateSituacionAprendizajeDescripcion(data) {
        return api.post("/ai/situaciones-aprendizaje/generar-descripcion/", data);
    }

    generateSituacionAprendizajeTabla(data) {
        return api.post("/ai/situaciones-aprendizaje/generar-tabla/", data);
    }

    generateSituacionesAprendizaje(data) {
        return api.post("/ai/situaciones-aprendizaje/generar/", data);
    }

    getEjercicios() {
        return api.get("/ai/ejercicios/");
    }

    saveEjercicio(data) {
        return api.post("/ai/ejercicios/", data);
    }

    generateEjercicio(data) {
        return api.post("/ai/ejercicios/generate/", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    checkActivity(data) {
        return api.post("/ai/checker/", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    generateRubric(data) {
        return api.post("/ai/rubrics/generate/", data);
    }

    getTools() {
        return api.get("/ai/tools/");
    }

    getToolDetails(tool_slug) {
        return api.get(`/ai/tools/${tool_slug}/`);
    }

    getToolOutput(tool_slug, data) {
        return api.post(`/ai/tools/${tool_slug}/`, data);
    }

    generateProgramacionContent(contentType, contextData) {
        return api.post('/ai/generate-programacion-content/', {
            content_type: contentType,
            context_data: contextData
        });
    }

    sendChatMessage(data) {
        return api.post("/ai/chat/", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getPreviousConversations() {
        return api.get("/ai/chat/");
    }

    getMessagesByConversationID(conversationId) {
        return api.get(`/ai/chat/${conversationId}/`);
    }

    deleteConversation(conversationId) {
        return api.delete(`/ai/chat/${conversationId}/`);
    }

    sendFeedback(tool, input, output, isPositive) {
        return api.post("/ai/feedback/", {"tool": tool, "input": input, "output": output, "isPositive": isPositive});
    }

    saveRubric(data) {
        return api.post("/ai/rubrics/", data);
    }

    getRubrics() {
        return api.get('/ai/rubrics/');
    }

    deleteRubric(id) {
        return api.delete(`/ai/rubrics/${id}/`);
    }

    updateRubric(id, data) {
        return api.put(`/ai/rubrics/${id}/`, data);
    }
}

export default new AIService();