import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Paper
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AIService from '../../../services/AIService';

const Introduccion = ({ programacion, setProgramacion, setIsGeneratingAI, updateLoadingState }) => {
  const [loading, setLoading] = useState({
    introduccion: false,
    tematica_hilo_conductor: false
  });

  // Notificar al componente padre sobre cambios en el estado de carga
  useEffect(() => {
    // Si cualquier campo está en estado de carga
    const isAnyLoading = Object.values(loading).some(value => value === true);
    if (updateLoadingState) {
      updateLoadingState(isAnyLoading);
    }
  }, [loading, updateLoadingState]);

  const handleChange = (field) => (event) => {
    setProgramacion(prev => ({
      ...prev,
      introduccion: {
        ...prev.introduccion,
        [field]: event.target.value
      }
    }));
  };

  const generarSugerencia = async (campo) => {
    // Actualizar el estado de carga local
    setLoading(prev => ({ ...prev, [campo]: true }));
    
    // Notificar al componente padre sobre el estado de generación de IA
    if (setIsGeneratingAI) setIsGeneratingAI(true);
    
    try {
      const response = await AIService.generateProgramacionContent(`introduccion-${campo}`, {
        nivel_educativo: programacion.nivel_educativo,
        asignaturas: programacion.asignaturas,
        comunidad_autonoma: programacion.comunidad_autonoma,
        titulo: programacion.titulo,
        id: programacion.id
      });
      
      const contenido = response.data.output || `Propuesta de ${campo} para la introducción`;
      
      setProgramacion(prev => ({
        ...prev,
        introduccion: {
          ...prev.introduccion,
          [campo]: contenido
        }
      }));
    } catch (error) {
      console.error(`Error generando sugerencia para ${campo}:`, error);
      setProgramacion(prev => ({
        ...prev,
        introduccion: {
          ...prev.introduccion,
          [campo]: `No se pudo generar una sugerencia para ${campo}. Por favor, introduce tu propio contenido.`
        }
      }));
    } finally {
      // Actualizar el estado de carga
      setLoading(prev => ({ ...prev, [campo]: false }));
      
      // Verificar si todos los campos han terminado de cargarse
      const todosCompletos = !Object.values({...loading, [campo]: false}).some(value => value === true);
      if (todosCompletos && setIsGeneratingAI) {
        setIsGeneratingAI(false);
      }
    }
  };

  useEffect(() => {
    const cargarContenido = async () => {
      const needsGeneration = !programacion.introduccion?.introduccion || !programacion.introduccion?.tematica_hilo_conductor;
      
      if (needsGeneration) {
        if (setIsGeneratingAI) setIsGeneratingAI(true);
        
        // Actualizar el estado de carga para cualquier campo que se cargará
        const newLoadingState = { ...loading };
        if (!programacion.introduccion?.introduccion) {
          newLoadingState.introduccion = true;
        }
        if (!programacion.introduccion?.tematica_hilo_conductor) {
          newLoadingState.tematica_hilo_conductor = true;
        }
        setLoading(newLoadingState);
      }
      
      if (!programacion.introduccion?.introduccion) {
        await generarSugerencia('introduccion');
      }
      if (!programacion.introduccion?.tematica_hilo_conductor) {
        await generarSugerencia('tematica_hilo_conductor');
      }
    };
    
    cargarContenido();
  }, []);

  // Limpiar estado al desmontar
  useEffect(() => {
    return () => {
      if (setIsGeneratingAI) {
        setIsGeneratingAI(false);
      }
      if (updateLoadingState) {
        updateLoadingState(false);
      }
    };
  }, [setIsGeneratingAI, updateLoadingState]);

  const SeccionIntroduccion = ({ titulo, campo, valor, helperText }) => (
    <Grid item xs={12}>
      <Paper elevation={0} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {titulo}
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={campo === 'introduccion' ? 6 : 4}
          value={valor || ''}
          onChange={handleChange(campo)}
          required
          helperText={helperText}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Button
          fullWidth
          variant="outlined"
          startIcon={loading[campo] ? <CircularProgress size={20} /> : <AutorenewIcon />}
          onClick={() => generarSugerencia(campo)}
          disabled={loading[campo]}
        >
          {loading[campo] ? 'Generando...' : 'Generar nueva sugerencia'}
        </Button>
      </Paper>
    </Grid>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Introducción
      </Typography>
      
      <Grid container spacing={3}>
        <SeccionIntroduccion 
          titulo="Introducción"
          campo="introduccion"
          valor={programacion.introduccion?.introduccion}
          helperText="Describe la introducción general de la programación didáctica"
        />
        
        <SeccionIntroduccion 
          titulo="Temática e Hilo Conductor"
          campo="tematica_hilo_conductor"
          valor={programacion.introduccion?.tematica_hilo_conductor}
          helperText="Describe la temática principal y el hilo conductor que seguirá la programación"
        />
      </Grid>
    </Box>
  );
};

export default Introduccion; 