import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AIService from '../../../services/AIService';

const Conclusiones = ({ programacion, setProgramacion }) => {
  const [loading, setLoading] = useState(false);

  const handleConclusionesChange = (event) => {
    setProgramacion({
      ...programacion,
      conclusiones: {
        ...programacion.conclusiones,
        conclusiones: event.target.value
      }
    });
  };

  const generarContenido = async () => {
    setLoading(true);
    try {
      const response = await AIService.generateProgramacionContent('conclusiones', {
        materia: programacion.asignaturas[0]?.name,
        nivel: programacion.nivel_educativo?.name,
        comunidad: programacion.comunidad_autonoma,
        tematica: programacion.introduccion?.tematica_hilo_conductor,
        id: programacion.id
      });

      const contenido = response.data.output || '';

      setProgramacion(prev => ({
        ...prev,
        conclusiones: {
          ...prev.conclusiones,
          conclusiones: contenido
        }
      }));
    } catch (error) {
      console.error('Error generando conclusiones:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!programacion.conclusiones?.conclusiones) {
      generarContenido();
    }
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Conclusiones
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-start" gap={2}>
            <TextField
              fullWidth
              multiline
              rows={6}
              label="Conclusiones"
              value={programacion.conclusiones?.conclusiones || ''}
              onChange={handleConclusionesChange}
              required
              helperText="Resume las conclusiones principales de la programación didáctica"
            />
            <Button
              variant="contained"
              onClick={generarContenido}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : <AutorenewIcon />}
              sx={{ mt: 1 }}
            >
              Regenerar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Conclusiones; 