export const COMUNIDADES = [
  {id: "ANDALUCIA", name: "Andalucía"},
  {id: "ARAGON", name: "Aragón"},
  {id: "ASTURIAS", name: "Asturias"},
  {id: "BALEARES", name: "Baleares"},
  {id: "CANARIAS", name: "Canarias"},
  {id: "CANTABRIA", name: "Cantabria"},
  {id: "CASTILLA_Y_LEON", name: "Castilla y León"},
  {id: "CASTILLA_LA_MANCHA", name: "Castilla-La Mancha"},
  {id: "CATALUNA", name: "Cataluña"},
  {id: "VALENCIA", name: "Comunidad Valenciana"},
  {id: "EXTREMADURA", name: "Extremadura"},
  {id: "GALICIA", name: "Galicia"},
  {id: "MADRID", name: "Madrid"},
  {id: "MURCIA", name: "Murcia"},
  {id: "NAVARRA", name: "Navarra"},
  {id: "PAIS_VASCO", name: "País Vasco"},
  {id: "LA_RIOJA", name: "La Rioja"},
  {id: "CEUTA", name: "Ceuta"},
  {id: "MELILLA", name: "Melilla"},
];

export const LEVELS = [
  { id: 28, name: 'Infantil 3 años' },
  { id: 29, name: 'Infantil 4 años' },
  { id: 30, name: 'Infantil 5 años' },
  { id: 1, name: '1º Primaria' },
  { id: 2, name: '2º Primaria' },
  { id: 3, name: '3º Primaria' },
  { id: 4, name: '4º Primaria' },
  { id: 5, name: '5º Primaria' },
  { id: 6, name: '6º Primaria' },
  { id: 7, name: '1º ESO' },
  { id: 8, name: '2º ESO' },
  { id: 9, name: '3º ESO' },
  { id: 10, name: '4º ESO' },
  { id: 13, name: '1º Bachillerato' },
  { id: 14, name: '2º Bachillerato' },
]; 