import TextField from "@mui/material/TextField";
import {Autocomplete, FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {LEVELS} from "../../../classrooms/utils";

const ToolField = (props) => {
    const {field, onChange} = props;

    switch (field.component) {
        case "Text":
            return <TextField
                key={field.name}
                label={field.label}
                defaultValue={field.initial_value}
                onChange={(e) => onChange(field.prompt_placeholder, e.target.value)}
                fullWidth
                required={field.is_required}
                placeholder={field.placeholder}
            />;
        case "TextArea":
            return <TextField
                label={field.label}
                multiline
                rows={4}
                defaultValue={field.initial_value}
                onChange={(e) => onChange(field.prompt_placeholder, e.target.value)}
                fullWidth
                required={field.is_required}
                placeholder={field.placeholder}
            />;
        case "Select":
            return <FormControl fullWidth required={field.is_required}>
                <InputLabel id={field.name}>{field.label}</InputLabel>
                <Select
                    id={field.name + "select"}
                    labelId={field.name}
                    label={field.label}
                    fullWidth
                    defaultValue={field.initial_value}
                    required={field.is_required}
                    onChange={(event) => onChange(field.prompt_placeholder, event.target.value)}
                >
                    <MenuItem key="vacio" value="">(Vacío)</MenuItem>
                    {field.options.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                </Select>
            </FormControl>
        case "Level":
            return <Autocomplete
                id={field.name}
                options={LEVELS}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => onChange(field.prompt_placeholder, value?.name)}
                renderInput={(params) =>
                    <TextField fullWidth required={field.is_required} label={field.label} variant="outlined" {...params}/>}
            />
        case "Autocomplete":
            return <Autocomplete
                freeSolo
                id={field.name}
                options={field.options}
                getOptionLabel={(option) => option.label}
                onChange={(e, value) => onChange(field.prompt_placeholder, value?.label)}
                renderInput={(params) =>
                    <TextField fullWidth required={field.is_required} label={field.label} variant="outlined" {...params}/>}
            />
        case "Multiselect":
            return <FormControl fullWidth required={field.is_required}>
                <InputLabel id={field.name}>{field.label}</InputLabel>
                <Select
                    id={field.name + "select"}
                    labelId={field.name}
                    label={field.label}
                    fullWidth
                    multiple
                    defaultValue={field.initial_value ? field.initial_value.split(';').filter(Boolean) : []}
                    required={field.is_required}
                    onChange={(event) => onChange(field.prompt_placeholder, event.target.value.join(';'))}
                >
                    {field.options.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                </Select>
            </FormControl>
        default:
            return <></>;
    }
}

export default ToolField;