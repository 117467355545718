/**
 * Utilities for managing feature flags based on subscription plans
 */

// Plan IDs
export const PLANS = {
  FREE: '',
  LEGACY: 'prod_QNp6Vw50uIT0WR',
  BASIC: 'prod_S147ktwHfcGVCO',
  PRO: 'prod_S149pvlgHLu2mZ'
};

/**
 * Check if user has a free or basic plan
 * @param {string} plan - User's current plan
 * @returns {boolean} - Whether user is on a free or basic plan
 */
export const isLimitedPlan = (plan) => {
  return plan === PLANS.FREE || plan === PLANS.BASIC;
};

/**
 * Check if user has a pro plan
 * @param {string} plan - User's current plan
 * @returns {boolean} - Whether user is on a pro plan
 */
export const isProPlan = (plan) => {
  return plan === PLANS.PRO;
};

/**
 * Get the maximum number of allowed situaciones de aprendizaje based on plan
 * @param {string} plan - User's current plan
 * @returns {number} - Maximum number of situaciones allowed
 */
export const getMaxSituacionesAllowed = (plan) => {
  return isLimitedPlan(plan) ? 3 : 10000000;
};

/**
 * Get the maximum number of programaciones didácticas allowed
 * @param {string} plan - User's current plan
 * @returns {number} - Maximum number of programaciones allowed
 */
export const getMaxProgramacionesAllowed = (plan) => {
  return isLimitedPlan(plan) ? 2 : 10000000;
};

/**
 * Check if downloading programaciones is allowed
 * @param {string} plan - User's current plan
 * @returns {boolean} - Whether user can download programaciones
 */
export const canDownloadProgramaciones = (plan) => {
  return !isLimitedPlan(plan);
};

/**
 * Check if a user can delete programaciones
 * @param {string} plan - User's current plan
 * @returns {boolean} - Whether user can delete programaciones
 */
export const canDeleteProgramaciones = (plan) => {
  return !isLimitedPlan(plan);
}; 