import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Paper
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AIService from '../../../services/AIService';

const MarcoLegislativo = ({ programacion, setProgramacion }) => {
  const [loading, setLoading] = useState({
    marco_legislativo: false,
    justificacion: false
  });

  const handleChange = (field) => (event) => {
    setProgramacion(prev => ({
      ...prev,
      marco_legislativo: {
        ...prev.marco_legislativo,
        [field]: event.target.value
      }
    }));
  };

  const generarSugerencia = async (campo) => {
    setLoading(prev => ({ ...prev, [campo]: true }));
    
    try {
      const response = await AIService.generateProgramacionContent(`marco_legislativo-${campo}`, {
        nivel_educativo: programacion.nivel_educativo?.id || '',
        asignaturas: programacion.asignaturas?.map(a => a.name).join(', ') || '',
        comunidad_autonoma: programacion.comunidad_autonoma || '',
        titulo: programacion.titulo || '',
        id: programacion.id
      });
      
      const contenido = response.data.output || `Propuesta de ${campo} para el marco legislativo`;
      
      setProgramacion(prev => ({
        ...prev,
        marco_legislativo: {
          ...prev.marco_legislativo,
          [campo]: contenido
        }
      }));
    } catch (error) {
      console.error(`Error generando sugerencia para ${campo}:`, error);
      setProgramacion(prev => ({
        ...prev,
        marco_legislativo: {
          ...prev.marco_legislativo,
          [campo]: `No se pudo generar una sugerencia para ${campo}. Por favor, introduce tu propio contenido.`
        }
      }));
    } finally {
      setLoading(prev => ({ ...prev, [campo]: false }));
    }
  };

  useEffect(() => {
    if (!programacion.marco_legislativo?.marco_legislativo) {
      generarSugerencia('marco_legislativo');
    }
    if (!programacion.marco_legislativo?.justificacion) {
      generarSugerencia('justificacion');
    }
  }, []);

  const SeccionMarcoLegislativo = ({ titulo, campo, valor, helperText }) => (
    <Grid item xs={12}>
      <Paper elevation={0} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {titulo}
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={6}
          value={valor || ''}
          onChange={handleChange(campo)}
          required
          helperText={helperText}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Button
          fullWidth
          variant="outlined"
          startIcon={loading[campo] ? <CircularProgress size={20} /> : <AutorenewIcon />}
          onClick={() => generarSugerencia(campo)}
          disabled={loading[campo]}
        >
          {loading[campo] ? 'Generando...' : 'Generar nueva sugerencia'}
        </Button>
      </Paper>
    </Grid>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Marco Legislativo y Justificación
      </Typography>
      
      <Grid container spacing={3}>
        <SeccionMarcoLegislativo 
          titulo="Marco Legislativo"
          campo="marco_legislativo"
          valor={programacion.marco_legislativo?.marco_legislativo}
          helperText="Describe el marco legislativo aplicable a la programación didáctica"
        />
        
        <SeccionMarcoLegislativo 
          titulo="Justificación"
          campo="justificacion"
          valor={programacion.marco_legislativo?.justificacion}
          helperText="Justifica la programación didáctica en base al marco legislativo"
        />
      </Grid>
    </Box>
  );
};

export default MarcoLegislativo; 