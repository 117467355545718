import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Paper
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AIService from '../../../services/AIService';

const Contexto = ({ programacion, setProgramacion }) => {
  const [loading, setLoading] = useState({
    contexto: false
  });

  const handleChange = (field) => (event) => {
    setProgramacion(prev => ({
      ...prev,
      contexto: {
        ...prev.contexto,
        [field]: event.target.value
      }
    }));
  };

  const generarSugerencia = async (campo) => {
    setLoading(prev => ({ ...prev, [campo]: true }));
    
    try {
      const response = await AIService.generateProgramacionContent(`contexto-${campo}`, {
        nivel_educativo: programacion.nivel_educativo?.id || '',
        asignaturas: programacion.asignaturas?.map(a => a.name).join(', ') || '',
        comunidad_autonoma: programacion.comunidad_autonoma || '',
        titulo: programacion.titulo || '',
        centro_educativo: programacion.centro_educativo || '',
        id: programacion.id
      });
      
      const contenido = response.data.output || `Propuesta de ${campo} para el contexto`;
      
      setProgramacion(prev => ({
        ...prev,
        contexto: {
          ...prev.contexto,
          [campo]: contenido
        }
      }));
    } catch (error) {
      console.error(`Error generando sugerencia para ${campo}:`, error);
      setProgramacion(prev => ({
        ...prev,
        contexto: {
          ...prev.contexto,
          [campo]: `No se pudo generar una sugerencia para ${campo}. Por favor, introduce tu propio contenido.`
        }
      }));
    } finally {
      setLoading(prev => ({ ...prev, [campo]: false }));
    }
  };

  useEffect(() => {
    if (!programacion.contexto?.contexto) {
      generarSugerencia('contexto');
    }
  }, []);

  const SeccionContexto = ({ titulo, campo, valor, helperText }) => (
    <Grid item xs={12}>
      <Paper elevation={0} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {titulo}
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={8}
          value={valor || ''}
          onChange={handleChange(campo)}
          required
          helperText={helperText}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Button
          fullWidth
          variant="outlined"
          startIcon={loading[campo] ? <CircularProgress size={20} /> : <AutorenewIcon />}
          onClick={() => generarSugerencia(campo)}
          disabled={loading[campo]}
        >
          {loading[campo] ? 'Generando...' : 'Generar nueva sugerencia'}
        </Button>
      </Paper>
    </Grid>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Contexto
      </Typography>
      
      <Grid container spacing={3}>
        <SeccionContexto 
          titulo="Contexto"
          campo="contexto"
          valor={programacion.contexto?.contexto}
          helperText="Describe el contexto del centro educativo y del alumnado"
        />
      </Grid>
    </Box>
  );
};

export default Contexto; 