import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AIService from "../../../services/AIService";
import {Card, Grid, Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import ToolField from "../../genericui/formcomponents/ai_forms/ToolField";
import {LoadingButton} from "@mui/lab";
import Progress from "../../genericui/feedback/Progress";
import ToolOutputDetails from "./ToolOutputDetails";
import IAFeedback from "../feedback/IAFeedback";

const IATool = (props) => {
    const {slug} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [iaDetail, setIADetail] = useState({});
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState(null);

    useEffect(() => {
        AIService.getToolDetails(slug).then((response) => {
            setIADetail(response.data);
            setIsLoading(false);
        })
    }, [slug])

    const onFormChange = (key, value) => {
        setForm({
            ...form,
            [key]: value
        });
    }

    const sendForm = (e) => {
        e.preventDefault();
        setLoading(true);
        AIService.getToolOutput(slug, form).then((response) => {
            setOutput(response.data.text);
            setLoading(false);
        }).catch(error => {
            console.error("Error al usar la herramienta:", error);
            setLoading(false);
        });
    }

    if (isLoading) {
        return <Skeleton variant="rectangular" width="100%" height="100%"/>
    }
    return <form onSubmit={sendForm}>
        <Grid container spacing={2} sx={{p: 2, display: 'flex'}}>
            <Grid item xs={12}>
                <Card variant="outlined"
                      sx={{p: 1, backgroundColor: '#FFFFFF80', color: 'text.primary', textAlign: 'center'}}>
                    <Typography variant="h4">{iaDetail.title}</Typography>
                    <Typography>{iaDetail.description}</Typography>
                </Card>
            </Grid>
            {iaDetail.fields.map((field) => <Grid item xs={12} key={field.id}>
                <ToolField field={field} onChange={onFormChange}/>
            </Grid>)}
            <Grid item xs={12}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               type="submit">¡Generar!</LoadingButton>
            </Grid>
            {
                loading && <Grid item xs={12}>
                    <Progress/>
                </Grid>}
            <Grid item xs={12}>
                {output && <>
                    <ToolOutputDetails output={output} title={iaDetail.title}/>
                    <IAFeedback tool={iaDetail.title} input={form} output={output}/>
                </>}
            </Grid>
        </Grid>
    </form>
};

export default IATool;