import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Grid,
  Typography,
  Autocomplete,
  InputLabel,
  Chip,
  Slider,
} from '@mui/material';
import { COMUNIDADES } from '../utils';
import SubjectService from '../../../services/SubjectService';
import { isLimitedPlan } from '../../../utils/PlanUtils';
import UpgradeAlert from '../../subscription/UpgradeAlert';

const LANGUAGES = [
  { id: 'espanol', name: 'Español' },
  { id: 'ingles', name: 'Inglés' },
  { id: 'frances', name: 'Francés' },
  { id: 'gallego', name: 'Gallego' },
  { id: 'catalan', name: 'Catalán' },
  { id: 'euskera', name: 'Euskera' },
  { id: 'valenciano', name: 'Valenciano' },
];

const InformacionBasica = ({ programacion, setProgramacion, nivelesEducativos, asignaturas, userPlan, maxSituaciones, setIsGeneratingAI, updateLoadingState }) => {
  const [region, setRegion] = useState(null);
  const [level, setLevel] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [infantilAreas, setInfantilAreas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comunidades, setComunidades] = useState(COMUNIDADES);
  const [errors, setErrors] = useState({
    titulo: '',
    nivel_educativo: '',
    asignaturas: ''
  });
  
  // Determinar si el usuario tiene un plan limitado
  const hasLimitedPlan = isLimitedPlan(userPlan);
  const maxAllowedSituaciones = maxSituaciones || 3;

  const isInfantilLevel = level?.id >= 28 && level?.id <= 30;

  // Notificar al componente padre sobre el estado de carga
  useEffect(() => {
    if (updateLoadingState) {
      updateLoadingState(loading);
    }
  }, [loading, updateLoadingState]);

  // Add effect to log programacion changes
  useEffect(() => {
    console.log('InformacionBasica received programacion:', programacion);
    console.log('Current asignaturas:', programacion.asignaturas);
  }, [programacion]);

  // This effect initializes the region and level state based on programacion props
  useEffect(() => {
    if (programacion) {
      const regionName = programacion.comunidad_autonoma;
      if (regionName && (!region || region.name !== regionName)) {
        const foundRegion = COMUNIDADES.find(r => r.name === regionName);
        if (foundRegion) {
          console.log('Setting region:', foundRegion);
          setRegion(foundRegion);
        }
      }

      const levelId = programacion.nivel_educativo?.id;
      if (levelId && (!level || level.id !== levelId)) {
        const foundLevel = nivelesEducativos.find(l => l.id === levelId);
        if (foundLevel) {
          console.log('Setting level:', foundLevel);
          setLevel(foundLevel);
        }
      }
    }
  }, [programacion?.comunidad_autonoma, programacion?.nivel_educativo?.id]);

  // Separate effect for loading subjects to avoid race conditions
  useEffect(() => {
    if (region?.id && level?.id) {
      setLoading(true);
      if (updateLoadingState) updateLoadingState(true);
      
      console.log('Loading subjects for region and level:', { region, level });
      SubjectService.getLomloeSubjectsByRegionAndLevel(region.id, level.id)
        .then(response => {
          console.log('Loaded subjects:', response.data);
          // Transform the subjects to match the structure we need
          const transformedSubjects = response.data.map(subject => ({
            ...subject,
            uuid: subject.id, // Add uuid field to match with programacion.asignaturas
          }));
          setSubjects(transformedSubjects);
          
          if (isInfantilLevel && transformedSubjects.length >= 3) {
            const areas = transformedSubjects.slice(0, 3);
            setInfantilAreas(areas);
            // Update programacion asignaturas for infantil level
            if (isInfantilLevel) {
              setProgramacion(prev => ({
                ...prev,
                asignaturas: areas.map(area => ({
                  id: area.id,
                  uuid: area.id,
                  name: area.name,
                  autonomous_community: area.autonomous_community,
                  is_regulated: area.is_regulated,
                  course_level: area.course_level
                }))
              }));
            }
          }
        })
        .catch(() => {
          setSubjects([]);
          setInfantilAreas([]);
        })
        .finally(() => {
          setLoading(false);
          if (updateLoadingState) updateLoadingState(false);
        });
    }
  }, [region?.id, level?.id]);

  // Limitar el número de situaciones de aprendizaje en planes limitados
  useEffect(() => {
    if (hasLimitedPlan && programacion?.numero_situaciones_aprendizaje > maxAllowedSituaciones) {
      setProgramacion(prev => ({
        ...prev,
        numero_situaciones_aprendizaje: maxAllowedSituaciones
      }));
    }
  }, [hasLimitedPlan, programacion?.numero_situaciones_aprendizaje, maxAllowedSituaciones]);

  // Set default idioma only once on initial mount
  useEffect(() => {
    if (!programacion?.idioma) {
      setProgramacion(prev => ({
        ...prev,
        idioma: 'Español'
      }));
    }
  }, []);

  // Validación en tiempo real
  useEffect(() => {
    const newErrors = {
      titulo: '',
      nivel_educativo: '',
      asignaturas: ''
    };

    if (!programacion.titulo || programacion.titulo === 'Nueva Programación Didáctica') {
      newErrors.titulo = 'El título no puede ser "Nueva Programación Didáctica"';
    }

    if (!programacion.nivel_educativo?.id) {
      newErrors.nivel_educativo = 'El nivel educativo es obligatorio';
    }

    if (!programacion.asignaturas?.length) {
      newErrors.asignaturas = 'Debes seleccionar al menos una asignatura';
    }

    setErrors(newErrors);
  }, [programacion.titulo, programacion.nivel_educativo, programacion.asignaturas]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProgramacion(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAutocompleteChange = (field) => (event, value) => {
    console.log('handleAutocompleteChange called with:', { field, value });
    
    setProgramacion(prev => {
      console.log('Previous programacion state:', prev);
      const newProgramacion = { ...prev };
      
      if (field === 'nivel_educativo') {
        setLevel(value);
        newProgramacion.nivel_educativo = value ? { id: parseInt(value.id), name: value.name } : { id: '', name: '' };
      } else if (field === 'comunidad_autonoma') {
        setRegion(value);
        newProgramacion.comunidad_autonoma = value?.name || '';
      } else if (field === 'asignaturas' && !isInfantilLevel) {
        if (Array.isArray(value)) {
          console.log('Setting new asignaturas:', value);
          newProgramacion.asignaturas = value.map(subject => ({
            id: subject.id,
            uuid: subject.id,
            name: subject.name,
            autonomous_community: subject.autonomous_community,
            is_regulated: subject.is_regulated,
            course_level: subject.course_level
          }));
          console.log('New asignaturas set:', newProgramacion.asignaturas);
        } else {
          newProgramacion.asignaturas = prev.asignaturas || [];
        }
      }
      
      console.log('Returning new programacion:', newProgramacion);
      return newProgramacion;
    });
  };

  const handleNumberOfSituacionesDeAprendizajeChange = (event, value) => {
    // Para planes limitados, no permitir más del máximo
    const limitedValue = hasLimitedPlan ? Math.min(value, maxAllowedSituaciones) : value;
    
    setProgramacion(prev => ({
      ...prev,
      numero_situaciones_aprendizaje: limitedValue
    }));
  };

  const handleComunidadChange = (e) => {
    const { value } = e.target;
    const comunidad = comunidades.find(c => c.id === value);
    setProgramacion(prev => ({
      ...prev,
      comunidad_autonoma: comunidad,
      asignaturas: []
    }));
  };

  const handleIdiomaChange = (event, newValue) => {
    setProgramacion(prev => ({
      ...prev,
      idioma: newValue ? newValue.name : 'Español'
    }));
  };

  const getAsignaturaValues = () => {
    return programacion.asignaturas ? programacion.asignaturas.map(asig => asig.uuid) : [];
  };

  const getCurrentLanguage = () => {
    const currentLang = programacion.idioma;
    return LANGUAGES.find(lang => lang.name === currentLang) || null;
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (updateLoadingState) {
        updateLoadingState(false);
      }
    };
  }, [updateLoadingState]);

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Información Básica
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="titulo"
            label="Título"
            value={programacion.titulo || ''}
            onChange={handleChange}
            required
            error={!!errors.titulo}
            helperText={errors.titulo}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            name="ano"
            label="Año Académico"
            value={programacion.ano || 2025}
            onChange={handleChange}
            type="number"
            inputProps={{ 
              min: 2020, 
              max: 2030, 
              inputMode: 'numeric',
              pattern: '[0-9]*' 
            }}
            required
            helperText="Introduce un año (por ejemplo: 2025)"
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={COMUNIDADES}
            value={region}
            getOptionLabel={(option) => option?.name || ''}
            onChange={handleAutocompleteChange('comunidad_autonoma')}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => 
              <TextField 
                {...params} 
                required 
                label="Comunidad Autónoma"
                variant="outlined"
              />
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={nivelesEducativos}
            value={level}
            disabled={!region}
            getOptionLabel={(option) => option?.name || ''}
            onChange={handleAutocompleteChange('nivel_educativo')}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => 
              <TextField 
                {...params} 
                required 
                label="Nivel Educativo"
                variant="outlined"
                error={!!errors.nivel_educativo}
                helperText={errors.nivel_educativo}
              />
            }
          />
        </Grid>

        <Grid item xs={12}>
          {isInfantilLevel ? (
            <Box>
              <InputLabel required>
                Áreas de Infantil
              </InputLabel>
              <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {infantilAreas.map((area) => (
                  <Chip 
                    key={area.id} 
                    label={area.name} 
                    color="primary" 
                  />
                ))}
              </Box>
            </Box>
          ) : (
            <Autocomplete
              multiple
              options={subjects}
              disabled={!subjects || subjects.length === 0 || loading}
              getOptionLabel={(option) => option?.name || ''}
              onChange={handleAutocompleteChange('asignaturas')}
              isOptionEqualToValue={(option, value) => {
                console.log('isOptionEqualToValue comparing:', {
                  option: option ? { id: option.id, name: option.name } : null,
                  value: value ? { id: value.id, uuid: value.uuid, name: value.name } : null
                });
                const match = option?.id === (value?.uuid || value?.id);
                console.log('Match result:', match);
                return match;
              }}
              value={subjects.filter(subject => {
                if (!Array.isArray(programacion.asignaturas)) {
                  console.log('programacion.asignaturas is not an array:', programacion.asignaturas);
                  return false;
                }

                console.log('Checking subject:', {
                  id: subject.id,
                  name: subject.name,
                  programacionAsignaturas: programacion.asignaturas
                });

                const isSelected = programacion.asignaturas.some(asig => {
                  const match = (asig?.uuid === subject.id) || (asig?.id === subject.id);
                  console.log('Checking if subject is selected:', {
                    subject: { id: subject.id, name: subject.name },
                    asignatura: asig ? { id: asig.id, uuid: asig.uuid, name: asig.name } : null,
                    match: match
                  });
                  return match;
                });
                
                console.log('Subject selected:', isSelected);
                return isSelected;
              })}
              loading={loading}
              renderInput={(params) => 
                <TextField 
                  {...params} 
                  required 
                  label="Asignaturas"
                  variant="outlined"
                  error={!!errors.asignaturas}
                  helperText={errors.asignaturas}
                />
              }
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={LANGUAGES}
            getOptionLabel={(option) => option.name}
            value={getCurrentLanguage()}
            onChange={handleIdiomaChange}
            renderInput={(params) => <TextField {...params} label="Idioma de la programación" />}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            name="centro_educativo"
            label="Centro Educativo"
            value={programacion.centro_educativo || ''}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item xs={12}>
          {hasLimitedPlan && (
            <UpgradeAlert 
              message={`Tu plan actual limita el número de situaciones de aprendizaje a: ${maxAllowedSituaciones}.`}
              show={true}
            />
          )}
          
          <InputLabel id="situaciones-aprendizaje-label" sx={{ mb: 1 }}>
            Número de situaciones de aprendizaje: {programacion.numero_situaciones_aprendizaje || 12}
          </InputLabel>
          <Slider
            aria-labelledby="situaciones-aprendizaje-label"
            value={programacion.numero_situaciones_aprendizaje || 12}
            onChange={handleNumberOfSituacionesDeAprendizajeChange}
            min={3}
            max={15}
            step={1}
            marks
            valueLabelDisplay="auto"
            disabled={hasLimitedPlan}
          />
          {hasLimitedPlan && (
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
              El número de situaciones de aprendizaje está fijado en {maxAllowedSituaciones} con tu plan actual.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default InformacionBasica; 