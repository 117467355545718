import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Paper
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AIService from '../../../services/AIService';

const AtencionDiversidad = ({ programacion, setProgramacion }) => {
  const [loading, setLoading] = useState({
    marco_legal: false,
    medidas_ordinarias: false,
    medidas_extraordinarias: false,
    acneae: false
  });

  const handleChange = (field) => (event) => {
    setProgramacion(prev => ({
      ...prev,
      atencion_diversidad: {
        ...prev.atencion_diversidad,
        [field]: event.target.value
      }
    }));
  };

  const generarSugerencia = async (campo) => {
    setLoading(prev => ({ ...prev, [campo]: true }));
    
    try {
      const response = await AIService.generateProgramacionContent(`atencion-diversidad-${campo}`, {
        nivel_educativo: programacion.nivel_educativo,
        asignaturas: programacion.asignaturas,
        comunidad_autonoma: programacion.comunidad_autonoma,
        titulo: programacion.titulo,
        centro_educativo: programacion.centro_educativo,
        id: programacion.id
      });
      
      const contenido = response.data.output || `Propuesta de ${campo} para atención a la diversidad`;
      
      setProgramacion(prev => ({
        ...prev,
        atencion_diversidad: {
          ...prev.atencion_diversidad,
          [campo]: contenido
        }
      }));
    } catch (error) {
      console.error(`Error generando sugerencia para ${campo}:`, error);
      setProgramacion(prev => ({
        ...prev,
        atencion_diversidad: {
          ...prev.atencion_diversidad,
          [campo]: `No se pudo generar una sugerencia para ${campo}. Por favor, introduce tu propio contenido.`
        }
      }));
    } finally {
      setLoading(prev => ({ ...prev, [campo]: false }));
    }
  };

  useEffect(() => {
    const campos = [
      'marco_legal',
      'medidas_ordinarias',
      'medidas_extraordinarias',
      'acneae'
    ];

    campos.forEach(campo => {
      if (!programacion.atencion_diversidad?.[campo]) {
        generarSugerencia(campo);
      }
    });
  }, []);

  const SeccionAtencionDiversidad = ({ titulo, campo, valor, helperText }) => (
    <Grid item xs={12}>
      <Paper elevation={0} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {titulo}
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={6}
          value={valor || ''}
          onChange={handleChange(campo)}
          required
          helperText={helperText}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Button
          fullWidth
          variant="outlined"
          startIcon={loading[campo] ? <CircularProgress size={20} /> : <AutorenewIcon />}
          onClick={() => generarSugerencia(campo)}
          disabled={loading[campo]}
        >
          {loading[campo] ? 'Generando...' : 'Generar nueva sugerencia'}
        </Button>
      </Paper>
    </Grid>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Atención a la Diversidad
      </Typography>
      
      <Grid container spacing={3}>
        <SeccionAtencionDiversidad 
          titulo="Marco Legal"
          campo="marco_legal"
          valor={programacion.atencion_diversidad?.marco_legal}
          helperText="Describe el marco legal que regula la atención a la diversidad"
        />
        
        <SeccionAtencionDiversidad 
          titulo="Medidas Ordinarias"
          campo="medidas_ordinarias"
          valor={programacion.atencion_diversidad?.medidas_ordinarias}
          helperText="Describe las medidas ordinarias de atención a la diversidad"
        />
        
        <SeccionAtencionDiversidad 
          titulo="Medidas Extraordinarias"
          campo="medidas_extraordinarias"
          valor={programacion.atencion_diversidad?.medidas_extraordinarias}
          helperText="Describe las medidas extraordinarias de atención a la diversidad"
        />
        
        <SeccionAtencionDiversidad 
          titulo="ACNEAE"
          campo="acneae"
          valor={programacion.atencion_diversidad?.acneae}
          helperText="Describe las medidas específicas para el alumnado con necesidades específicas de apoyo educativo"
        />
      </Grid>
    </Box>
  );
};

export default AtencionDiversidad; 