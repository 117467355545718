import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AIService from '../../../services/AIService';

const Bibliografia = ({ programacion, setProgramacion }) => {
  const [loading, setLoading] = useState({
    bibliografia: false,
    webgrafia: false
  });

  const handleBibliografiaChange = (field) => (event) => {
    setProgramacion({
      ...programacion,
      bibliografia: {
        ...programacion.bibliografia,
        [field]: event.target.value
      }
    });
  };

  const generarContenido = async (tipo) => {
    setLoading(prev => ({ ...prev, [tipo]: true }));
    try {
      const response = await AIService.generateProgramacionContent(`bibliografia-${tipo}`, {
        materia: programacion.asignaturas[0]?.name,
        nivel: programacion.nivel_educativo?.name,
        comunidad: programacion.comunidad_autonoma,
        tematica: programacion.introduccion?.tematica_hilo_conductor,
        id: programacion.id
      });

      const contenido = response.data.output || '';

      setProgramacion(prev => ({
        ...prev,
        bibliografia: {
          ...prev.bibliografia,
          [tipo]: contenido
        }
      }));
    } catch (error) {
      console.error(`Error generando ${tipo}:`, error);
    } finally {
      setLoading(prev => ({ ...prev, [tipo]: false }));
    }
  };

  useEffect(() => {
    const shouldGenerateContent = !programacion.bibliografia?.bibliografia &&
                                !programacion.bibliografia?.webgrafia;

    if (shouldGenerateContent) {
      generarContenido('bibliografia');
      generarContenido('webgrafia');
    }
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Bibliografía
      </Typography>

      <Alert severity="warning" sx={{ mb: 3 }}>
        Las referencias bibliográficas generadas automáticamente son orientativas y pueden no ser precisas. 
        Te recomendamos verificar y personalizar las referencias según las fuentes que realmente hayas utilizado 
        en tu programación didáctica.
      </Alert>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-start" gap={2}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Bibliografía"
              value={programacion.bibliografia?.bibliografia || ''}
              onChange={handleBibliografiaChange('bibliografia')}
              required
              helperText="Lista las referencias bibliográficas utilizadas"
            />
            <Button
              variant="contained"
              onClick={() => generarContenido('bibliografia')}
              disabled={loading.bibliografia}
              startIcon={loading.bibliografia ? <CircularProgress size={20} /> : <AutorenewIcon />}
              sx={{ mt: 1 }}
            >
              Regenerar
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-start" gap={2}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Webgrafía"
              value={programacion.bibliografia?.webgrafia || ''}
              onChange={handleBibliografiaChange('webgrafia')}
              required
              helperText="Lista las referencias web y recursos online utilizados"
            />
            <Button
              variant="contained"
              onClick={() => generarContenido('webgrafia')}
              disabled={loading.webgrafia}
              startIcon={loading.webgrafia ? <CircularProgress size={20} /> : <AutorenewIcon />}
              sx={{ mt: 1 }}
            >
              Regenerar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Bibliografia; 